import React from 'react';
import { CommonDialog, CommonDialogActions, CommonDialogContent } from './CommonDialog';
import CancelButton from './CancelButton';
import CommonButton from './CommonButton';
import closePic from '../../assets/icons/close.png';
import { Typography, makeStyles } from '@material-ui/core';

const useClasses = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            width: '500px'
        }
    },
    title: {
        color: '#454545',
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '31px',
        paddingTop: '70px'
    },
    closeicon: {
        position: 'absolute',
        right: '18px',
        top: '20px',
        cursor: 'pointer',
        width: '20px'
    },
    actionsContainer: {
        justifyContent: 'center',
        padding: '80px 0 56px'
    },
    button: {
        width: '130px'
    }
}))

const ConfirmDialog = (props) => {
    const classes = useClasses();
    return <CommonDialog
        open={props.open}
        onClose={props.onClose}
        className={classes.container}
    >
        <CommonDialogContent>
            <Typography className={classes.title} variant='h6'>{props.title}</Typography>
            <img alt='close' className={classes.closeicon} src={closePic} onClick={props.onClose} />
        </CommonDialogContent>
        <CommonDialogActions className={classes.actionsContainer}>
            <CancelButton onClick={props.onClose} className={classes.button}>
                Cancel
            </CancelButton>
            <CommonButton onClick={props.onConfirm} startIcon={props.icon}  className={classes.button}>
                {props.confirmText}
            </CommonButton>
        </CommonDialogActions>
    </CommonDialog>
}

export default ConfirmDialog;