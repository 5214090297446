import { useMemo, useState } from "react";
import AWS from "aws-sdk";
import ConfirmDialog from "../../components/ui/ConfirmDialog";
import redeployWhiteIcon from '../../assets/icons/redeploy_white.svg';
import { makeStyles } from "@material-ui/core";
import { useDispatchReducerContext } from "../../store";
import { DISMISS_APP_LOADING, SHOW_APP_LOADING, SHOW_TOAST } from "../../store/dispatchNames";
import { cb2Promise, useGetAssumedKeysForDeployAccounts, useHandleAwsAuthErr } from "../../common/AwsUtil";

const useRedeployConfirmClasses = makeStyles((theme) => ({
    redeployActionIcon: {
        width: '20px',
        height: '20px',
        position: 'relative',
        top: '-1px'
    },
}))

export default function useRedeploy(owner, actionList, pipelineType, envExecutionIdMap, onRefreshRequest) {
    const classes = useRedeployConfirmClasses();
    const handleAwsAuthErr = useHandleAwsAuthErr();
    const [isShowConfirmRedeploy, setIsShowConfirmRedeploy] = useState(false);
    const dispatch = useDispatchReducerContext();
    const getAssumedKeys = useGetAssumedKeysForDeployAccounts();
    const [action, setAction] = useState(null);

    const actionListWithRedeployStatus = useMemo(() => {
        if (pipelineType.toLowerCase() !== 'application') return actionList;

        return actionList.map((action) => {
            const envExecutionIdItem = envExecutionIdMap?.find(item => item.env === action.actionName);
            return {
                ...action,
                enabledRedeploy: envExecutionIdMap &&
                    ['DEPLOY-IN-PROD', 'DEPLOY-IN-QA', 'DEPLOY-IN-TEST'].includes(action.actionName) && 
                    !envExecutionIdItem
            };
        })
    }, [actionList, pipelineType, envExecutionIdMap]);

    const handleRedeployClick = (action) => {
        setIsShowConfirmRedeploy(true);
        setAction(action);
    }

    const redeployProject = async () => {
        let env = null;
        switch (action.actionName) {
            case "DEPLOY-IN-TEST":
                env = 'test'
                break;
            case "DEPLOY-IN-QA":
                env = 'qa'
                break;
            case "DEPLOY-IN-PROD":
                env = 'prod'
                break;
        
            default:
                break;
        }
        dispatch({
            type: SHOW_APP_LOADING
        })
        try {
            const params = await getAssumedKeys(owner, env, 'w');
            const codedeploy = new AWS.CodeDeploy(params);

            await cb2Promise(codedeploy, "createDeployment", {
                applicationName: action.input.configuration.ApplicationName,
                deploymentGroupName: action.input.configuration.DeploymentGroupName,
                revision: {
                    revisionType: 'S3',
                    s3Location: {
                        ...action.input.inputArtifacts[0].s3location,
                        bundleType: 'zip'
                    }
                }

            })
            setIsShowConfirmRedeploy(false);
            dispatch({
                type: SHOW_TOAST,
                payload: {
                    message: 'Project redeployment started successfully',
                    severity: 'success'
                }
            });
            setTimeout(() => {
                onRefreshRequest();
            }, 2000);
        } catch (err) {
            if (!handleAwsAuthErr(err)) {
                dispatch({
                    type: SHOW_TOAST,
                    payload: {
                        message: err.message,
                        severity: 'error'
                    }
                });
            }
        } finally {
            dispatch({
                type: DISMISS_APP_LOADING
            })
        }
    }

    const redeployComp = <ConfirmDialog
        open={isShowConfirmRedeploy}
        onClose={() => setIsShowConfirmRedeploy(false)}
        title={`Are you sure you want to redeploy this version?`}
        onConfirm={redeployProject}
        confirmText="Redeploy"
        icon={<img className={classes.redeployActionIcon} src={redeployWhiteIcon} alt='trigger' />}
    />

    return {
        handleRedeployClick,
        actionListWithRedeployStatus,
        redeployComp
    }
}