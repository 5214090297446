import React, { useState } from 'react';
import { Box, CircularProgress, makeStyles, Typography, Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@material-ui/core';
import Decending from '../../assets/icons/sort_decending.png'; 
import InfoOutlined from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
    },
    table: {
        marginBottom: '8px',
    },
    tableHeader: {
        backgroundColor: '#ECECEC',
        position: 'relative',
    },
    tableHeaderCell: {
        fontWeight: 'bold',
        color: "#4C4948",
        fontSize: '13px',
        fontFamily: 'Roboto',
        borderTop: '6px solid #E66400',
    },
    tableContent: {
        padding: '8px',
        fontSize: '13px',
        fontWeight: 400,
        fontFamily: 'Roboto',
    },
    metadataRow: {
        marginBottom: '16px',
        fontSize: '13px',
        fontFamily: 'Roboto',
    },
    metadataItem: {
        marginRight: '16px',
        display: 'inline-block',
        fontSize: '13px',
        fontWeight: 400,
        fontFamily: 'Roboto',
    },
    tableCell: {
        fontSize: '13px',
        fontWeight: 400,
        fontFamily: 'Roboto',
        padding: '10px 16px',
    },
    tableRow: {
        height: '32px',
    },
    expandIcon: {
        transition: 'transform 0.3s',
        width: "20px",
        height: "20px",
    },
    expandedIcon: {
        transform: 'rotate(180deg)',
    },
    collapsedIcon: {
        transform: 'rotate(180deg)',
    },
    noData: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px',
        textAlign: 'center',
        color: '#666',
    },
    infoIcon: {
        marginRight: '8px',
    },
    description: {
        marginTop: '8px',
        fontSize: '14px',
    },
}));

/**
 * @param {Object} props
 * @param {boolean} props.loading - Indicates if data is being loaded
 * @param {Object} props.data - The data to display
 * @param {string} props.fontSizeClass - The font size class to apply
 */
const Database = ({ loading, data, fontSizeClass }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(null);

    if (loading) {
        return (
            <Box className={classes.container}>
                <CircularProgress />
            </Box>
        );
    }

    if (!data || typeof data !== 'object' || Object.keys(data).length === 0) {
        return <Typography>No data available</Typography>;
    }

    const handleAccordionChange = (tableName) => (event, isExpanded) => {
        setExpanded(isExpanded ? tableName : null);
    };

    return (
        <Box className={classes.container}>
            {Object.keys(data).map(tableName => (
                <Accordion
                    key={tableName}
                    expanded={expanded === tableName}
                    onChange={handleAccordionChange(tableName)}
                    className={classes.table}
                >
                    <AccordionSummary
                        expandIcon={
                            <img
                  src={Decending}
                  alt="Expand More"
                className={`${classes.expandIcon} ${expanded === tableName ? classes.expandedIcon : classes.collapsedIcon}`}
                            />
                        }
                    >
                        <Box>
                            <Typography className={fontSizeClass}><strong>Table : {tableName}</strong></Typography>
                            {data[tableName].Description && (
                                <Typography className={classes.description}>{data[tableName].Description}</Typography>
                            )}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box width="100%">
                            <Box className={classes.metadataRow}>
                                <Typography className={classes.metadataItem}><strong>Create Time :</strong> {data[tableName]?.CreateTime}</Typography>
                                <Typography className={classes.metadataItem}><strong>Update Time :</strong> {data[tableName]?.UpdateTime}</Typography>
                                <Typography className={classes.metadataItem}><strong>Catalog ID :</strong> {data[tableName]?.CatalogId}</Typography>
                                <Typography className={classes.metadataItem}><strong>Version ID :</strong> {data[tableName]?.VersionId}</Typography>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="columns table">
                                            <TableHead>
                                                <TableRow className={classes.tableHeader}>
                                                    <TableCell className={classes.tableHeaderCell}><strong>Column Name</strong></TableCell>
                                                    <TableCell className={classes.tableHeaderCell}><strong>Type</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data[tableName]?.StorageDescriptor?.Columns?.length > 0 ? (
                                                    data[tableName].StorageDescriptor.Columns.map((column) => (
                                                        <TableRow key={column.Name} className={classes.tableRow}>
                                                            <TableCell className={classes.tableCell}>{column.Name}</TableCell>
                                                            <TableCell className={classes.tableCell}>{column.Type}</TableCell>
                                                        </TableRow>
                                                    ))
                                                ) : (
                                                    <TableRow className={classes.tableRow}>
                                                        <TableCell colSpan={2} className={classes.noData}>
                                                            <InfoOutlined className={classes.infoIcon} />
                                                            <Typography>No columns available for {tableName}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="partition keys table">
                                            <TableHead>
                                                <TableRow className={classes.tableHeader}>
                                                    <TableCell className={classes.tableHeaderCell}><strong>Partition Key Name</strong></TableCell>
                                                    <TableCell className={classes.tableHeaderCell}><strong>Type</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data[tableName]?.PartitionKeys?.length > 0 ? (
                                                    data[tableName].PartitionKeys.map((key) => (
                                                        <TableRow key={key.Name} className={classes.tableRow}>
                                                            <TableCell className={classes.tableCell}>{key.Name}</TableCell>
                                                            <TableCell className={classes.tableCell}>{key.Type}</TableCell>
                                                        </TableRow>
                                                    ))
                                                ) : (
                                                    <TableRow className={classes.tableRow}>
                                                        <TableCell colSpan={2} className={classes.noData}>
                                                            <InfoOutlined className={classes.infoIcon} />
                                                            <Typography>No partition key data available for {tableName}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};

export default Database;
