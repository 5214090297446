import React from "react";

import { useDispatchReducerContext, useStateReducerContext } from '../../store';
import { CLEAR_ACCESSINFO } from '../../store/dispatchNames';

import Popover from '@material-ui/core/Popover';
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";

import logoutIcon from '../../assets/icons/logout.svg';
import logoutHoverIcon from '../../assets/icons/logout_hover.svg';
import avatarIcon from '../../assets/icons/avatar.svg';
import helpIcon from '../../assets/icons/help_center.svg';

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiPaper-root': {
            translate: '30px 14px',
            width: '350px',
            boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.25)'
        }
    },
    titleContainer: {
        background: '#ECECEC',
        padding: '8px 22px',
        borderBottom: '1px solid #CCCCCC'
    },
    title: {
        fontSize: '15px',
        fontWeight: 700,
        lineHeight: '24px',
        color: '#4C4948'
    },
    close: {
        float: 'right',
        cursor: 'pointer'
    },
    userInfoContainer: {
        textAlign: 'center',
        padding: '28px 0 22px',
        borderBottom: '1px solid #CCCCCC'
    },
    avatar: {
        margin: '0 auto',
        width: '65px',
        height: '65px',
    },
    workno: {
        color: '#4C4948',
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: 1.5,
        marginTop: '5px',
        textTransform: 'uppercase'
    },
    buttonsContainer: {
        padding: '18px 27px',
        borderBottom: '1px solid #CCCCCC',
        display: 'block',
        '&:hover': {
            background: '#F7EED7',
        }
    },
    helplink: {
        color: '#4C4948',
        fontSize: '14px',
        lineHeight: '24px',
        '& > *': {
            verticalAlign: 'middle',
        }
    },
    helpicon: {
        width: '18px',
        height: '18px',
        marginRight: '8px',
        position: 'relative',
        top: '-1px'
    },
    logoutContainer: {
        padding: '18px 0',
        textAlign: 'center'
    },
    logouticon: {
        width: '24px',
        height: '24px',
        marginRight: '8px',
        position: 'relative',
        top: '-1px'
    },
    logout: {
        color: '#E66400',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: 1.5,
        cursor: 'pointer',
        '& > *': {
            verticalAlign: 'middle',
        },
        '&:hover': {
            color: '#AC4B00',
            textDecorationLine: 'underline'
        },
        '&:hover img': {
            content: `url(${logoutHoverIcon})`
        }
    }
}));

const AppUserPopover = function (props) {
    const dispatch = useDispatchReducerContext();
    const storeState = useStateReducerContext();
    const classes = useStyles();

    const handleClickLogout = () => {
        dispatch({type: CLEAR_ACCESSINFO})
    }
    return <Popover
        className={classes.container}
        open={props.open}
        anchorEl={props.anchorEl}
        onClose={props.onClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
    >
        <Box className={classes.titleContainer}>
            <Typography className={classes.title} variant="h6">
                User Menu
                <CloseIcon onClick={props.onClose} className={classes.close} />
            </Typography>
        </Box>
        <Box className={classes.userInfoContainer}>
            <img src={avatarIcon} className={classes.avatar} alt="avatar" />
            <Typography className={classes.workno}>{storeState.username}</Typography>
        </Box>
        <Link to="/help" onClick={props.onClose} className={classes.buttonsContainer}>
            <Typography className={classes.helplink}>
                <img src={helpIcon} className={classes.helpicon} alt="help" />
                Help Center
            </Typography>
        </Link>
        <Box className={classes.logoutContainer}>
            <Typography className={classes.logout} onClick={handleClickLogout}>
                <img src={logoutIcon} alt="logout" className={classes.logouticon} />
                Logout
            </Typography>
        </Box>
    </Popover>;
}

export default AppUserPopover;