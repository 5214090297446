import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

import nodataPic from '../../assets/nodata.png';

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: 'center',
        color: '#C4C4C4'
    },
    nodataPic: {
        width: '450px'
    },
    title: {
        fontSize: '36px',
        fontWeight: 500,
        lineHeight: '50px'
    },
    subTitle: {
        color: '#C4C4C4',
        fontSize: '20px',
        fontWeight: 400
    }
}));

const NoData = (props) => {
    const classes = useStyles();
    return <Box className={classes.container + ' ' + props.className}>
        <img src={nodataPic} alt='no data' className={classes.nodataPic} />
        <Typography variant='h3' className={classes.title}>{props.title}</Typography>
        <Typography variant='caption' className={classes.subTitle}>{props.subTitle}</Typography>
    </Box>
}

export default NoData;