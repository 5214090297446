import { Box, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import Decimal from 'decimal.js';
import React, { useCallback, useMemo, useState } from 'react';
import { AutoSizer, MultiGrid } from 'react-virtualized';
import moment from 'moment';
import searchIcon from '../../assets/icons/search.svg';
import costTotalTableIcon from '../../assets/icons/cost_total_table.svg';
import { COLOR_LIST } from './CostGraph';

const useStyles = makeStyles((theme) => ({
    container: {
        fontFamily: 'Roboto'
    },
    loadingContainer: {
        textAlign: 'center',
        paddingTop: '40px'
    },
    loading: {
        color: '#E66400',
        marginLeft: '6px',
        position: 'relative',
        top: '1px'
    },
    title: {
        padding: "36px 28px 22px",
        color: "#454545",
        fontWeight: 600,
        fontSize: '22px',
        letterSpacing: '-0.05px'
    },
    searchContainer: {
        background: '#ECECEC',
        padding: '14px 36px',
        position: 'relative'
    },
    searchIcon: {
        height: "16px",
        width: "16px",
        position: "absolute",
        pointerEvents: "none",
        top: '25px',
        left: '54px'
    },
    search: {
        width: '560px',
        height: '35px',
        borderRadius: '22px',
        border: '1px solid #4C4948',
        fontSize: '14px',
        paddingLeft: '40px',
        '&:focus': {
            outline: 'none'
        },
        '&::placeholder': {
            color: '#cccccc'
        }
    },
    th: {
        padding: '3px 0',
        fontWeight: 400,
        background: '#ECECEC',
        fontSize: '13px',
        color: '#4C4948'
    },
    thContent: {
        padding: '14px 20px',
        borderRight: '0.5px solid #cccccc'
    },
    thContentNoBorder: {
        padding: '14px 20px'
    },
    td: {
        padding: '3px 0',
        fontWeight: 400,
        background: '#ffffff',
        fontSize: '13px',
        color: '#4C4948',
        boxSizing: 'border-box',
        position: 'relative',
        borderBottom: '0.5px solid #cccccc',
    },
    firstColumnTd: {
        paddingLeft: '6px'
    },
    colorDot: {
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        position: 'absolute',
        left: '10px',
        top: '21px',
    },
    totalDotIcon: {
        width: '13px',
        height: '13px',
        borderRadius: '50%',
        position: 'absolute',
        left: '8px',
        top: '19px',
    },
    tdContent: {
        padding: '14px 20px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    tdContentRightBorder: {
        borderRight: '0.5px solid #cccccc',
    },
    tdTxt: {}
}));

const CostTable = (props) => {
    const classes = useStyles();
    const { detailedData, granularity, dimension, loading } = props;

    const [searchTxt, setSearchTxt] = useState("");

    const tableData = useMemo(() => {
        if (!detailedData?.ResultsByTime) return [];

        const dataMap = {};

        detailedData.ResultsByTime.forEach(item => {
            item.Groups.forEach(groupItem => {
                if (!dataMap[groupItem.Keys[0]]) {
                    dataMap[groupItem.Keys[0]] = {};
                }
                let key = item.TimePeriod.Start;
                if (granularity === 'MONTHLY') {
                    key = moment(item.TimePeriod.Start, 'YYYY-MM-DD').format('MMM YYYY');
                }
                if (dataMap[groupItem.Keys[0]][key]) {
                    dataMap[groupItem.Keys[0]][key] = Decimal.add(groupItem.Metrics.UnblendedCost.Amount, dataMap[groupItem.Keys[0]][key]).toString();
                } else {
                    dataMap[groupItem.Keys[0]][key] = groupItem.Metrics.UnblendedCost.Amount;
                }

            });
        });

        const costList = Object.entries(dataMap).map(([key, value]) => {
            const total = Decimal.sum(...Object.values(value)).toString();
            return {
                key,
                total,
                ...value,
            }
        }).sort((a, b) => b.total - a.total).map((item, index, arr) => {
            item.color = COLOR_LIST[index];
            if (index === 9 && arr.length > 10) {
                item.color = undefined;
            }

            return item;
        });
        const totalItem = costList.reduce((total, item) => {
            Object.entries(item).forEach(([key, value]) => {
                if (key === 'key' || key === 'color') return;
                if (total[key]) {
                    total[key] = Decimal.add(value, total[key]).toString();
                } else {
                    total[key] = value;
                }
            });

            return total;
        }, {
            key: 'Total costs',
            color: 'total'
        })
        costList.unshift(totalItem);

        return costList;

    }, [detailedData, granularity]);

    const calculateTableData = useMemo(() => {
        if (searchTxt === '') return tableData;
        return tableData.filter(item => {
            return item.key.toLowerCase().includes(searchTxt.toLowerCase());
        })
    }, [tableData, searchTxt]);

    const columns = useMemo(() => {
        if (!detailedData?.ResultsByTime) return [];

        return detailedData.ResultsByTime.map((item) => {
            let key = item.TimePeriod.Start;
            if (granularity === 'MONTHLY') {
                key = moment(item.TimePeriod.Start, 'YYYY-MM-DD').format('MMM YYYY');
            }
            return key;
        });

    }, [detailedData, granularity]);

    const dimensionTxt = useMemo(() => {
        switch (dimension) {
            case 'None':
                return 'None';
            case 'Owner':
                return 'Product Owner';
            case 'SERVICE':
                return 'AWS Service';
            case 'Service':
                return 'Component';
            case 'Env':
                return 'Env';
            case 'INSTANCE_TYPE':
                return 'Instance Type';
            default:
                return '';
        }
    }, [dimension]);

    const cellRenderer = useCallback(({ columnIndex, key, rowIndex, style }) => {
        if (rowIndex === 0) {
            let text = '';
            let contentCss = classes.thContent;
            switch (columnIndex) {
                case 0:
                    text = dimensionTxt;
                    break;
                case 1:
                    text = 'Total';
                    break;
                default:
                    text = columns[columnIndex - 2]
                    break;
            }

            if (columnIndex === columns.length + 1) {
                contentCss = classes.thContentNoBorder;
            }
            return <div key={key} style={style} className={classes.th}>
                <div className={contentCss}>{text}</div>
            </div>
        }
        let dataKey = 'key';
        let contentCss = classes.tdContent;
        if (columnIndex === 1) {
            dataKey = 'total';
            contentCss = `${classes.tdContentRightBorder} ${contentCss}`;
        } else if (columnIndex > 1) {
            dataKey = columns[columnIndex - 2];
        }

        const txt = calculateTableData[rowIndex - 1][dataKey];
        let color = undefined;
        let contentTxt = '';
        let tooltipTxt = undefined;
        let fontWeight = 400;
        if (calculateTableData[rowIndex - 1]?.color === 'total') {
            fontWeight = 600;
        }
        if (columnIndex > 0) {
            if (txt) {
                contentTxt = new Decimal(txt).toNumber().toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD'
                });
                tooltipTxt = `$${txt}`
            } else {
                contentTxt = '-';
            }
        } else {
            contentTxt = txt;
            tooltipTxt = txt;
            color = calculateTableData[rowIndex - 1].color;
        }
        return <div key={key} style={style} className={`${classes.td}${columnIndex === 0?` ${classes.firstColumnTd}`: ''}`}>
            {color === 'total' && <img src={costTotalTableIcon} alt='total icon' className={classes.totalDotIcon} />}
            {color && color !== 'total' && <div className={classes.colorDot} style={{background: color}}></div>}
            <div className={contentCss} title={tooltipTxt} style={{fontWeight}}>{contentTxt}</div>
        </div>;

    }, [dimensionTxt, columns, calculateTableData, classes]);

    return <Box>
        <Typography className={classes.title}>Cost and Usage Breakdown</Typography>
        <Box className={classes.searchContainer}>
            <input className={classes.search} placeholder='Search cost and usage breakdown' onChange={(e) => setSearchTxt(e.target.value)} />
            <img src={searchIcon} alt='search icon' className={classes.searchIcon} />
        </Box>
        <Box className={classes.container}>
            {
                loading ? <Box className={classes.loadingContainer}>
                    <CircularProgress className={classes.loading} size={32} />
                </Box> : <AutoSizer disableHeight>
                    {({ width }) => (
                        <MultiGrid
                            fixedColumnCount={2}
                            fixedRowCount={1}
                            cellRenderer={cellRenderer}
                            columnWidth={(width > 117 * (columns.length + 2)) ? (width / (columns.length + 2)) : 117}
                            columnCount={columns.length + 2}
                            enableFixedColumnScroll
                            enableFixedRowScroll
                            height={562}
                            rowHeight={50}
                            rowCount={calculateTableData.length + 1}
                            width={width}
                            hideTopRightGridScrollbar
                            hideBottomLeftGridScrollbar
                        />
                    )}
                </AutoSizer>
            }

        </Box>
    </Box>

}

export default CostTable;