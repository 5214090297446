export function getConstants() {
	const url = window.location.href;
	let constants = { };
	if (url.indexOf('cicd.telematics.udtrucks.com') !== -1) {
		constants.dynamoDBName = "project-pipe-details";
	} else if (url.indexOf('d3b8wf4dkwzbhq') !== -1) {
		constants.environment = "Test";
		constants.dynamoDBName = "project-pipe-details-test";
	} else {
		constants.environment = "Test";
		constants.dynamoDBName = "project-pipe-details-test";
	}
	
	return constants;
}