import React, { useMemo, useState } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import bookmarkedIcon from '../../assets/icons/bookmarked.png';
import unbookmarkedIcon from '../../assets/icons/unbookmarked.png';
import copyIcon from '../../assets/icons/copy.png';


import { useDispatchReducerContext, useStateReducerContext } from '../../store';
import { ADD_BOOKMARK, REMOVE_BOOKMARK } from '../../store/dispatchNames';
import CommonTooltip from '../../components/ui/CommonTooltip';

const useStyles = makeStyles((theme) => ({
    projectinfotitle: {
        color: '#4C4948',
        fontWeight: 400,
        lineHeight: '20px',
        marginTop: '12px',
        background: '#ECECEC',
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '0.5px solid #CCCCCC'
    },
    projectinfocontent: {
        color: '#4D4E53',
        fontWeight: 400,
        background: '#FFFFFF',
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '0.5px solid #C4C4C4'
    },
    'icon-btn': {
        position: 'absolute',
        right: '20px',
        top: '7px',
        padding: '6px',
        '&:hover': {
            backgroundColor: '#F7EED7'
        }
    },
    bookmarkIcon: {
        width: '24px',
        height: '24px',
    },
    'projectinfo-name-content': {
        position: 'relative',
        paddingLeft: '28px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        width: '33%',
        fontSize: '13px',
    },
    'projectinfo-area-content': {
        paddingLeft: '28px',
        width: '12.8%',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        textTransform: 'uppercase',
        '& p': {
            fontSize: '13px',
        }
    },
    'aws-account-content': {
        paddingLeft: '28px',
        width: '18.2%',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        '& p': {
            fontSize: '13px',
        }
    },
    'projectinfo-url-content': {
        position: 'relative',
        paddingLeft: '28px',
        paddingRight: '62px',
        lineHeight: '16px',
        height: '50px',
        fontSize: '13px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        flex: 1
    },
    'projectinfo-name': {
        padding: '10px 0 10px 28px',
        width: '33%',
        fontSize: '13px',
        borderRight: '0.5px solid #CCCCCC'
    },
    'projectinfo-area': {
        padding: '10px 0 10px 28px',
        width: '12.8%',
        fontSize: '13px',
        borderRight: '0.5px solid #CCCCCC'
    },
    'aws-account': {
        padding: '10px 0 10px 28px',
        width: '18.2%',
        fontSize: '13px',
        borderRight: '0.5px solid #CCCCCC'
    },
    'projectinfo-url': {
        padding: '10px 0 10px 28px',
        fontSize: '13px',
        flex: 1
    },
    'area-loading': {
        color: '#E66400',
        width: '16px !important',
        height: '16px !important',
    },
    copyIcon: {
        width: '24px',
        height: '24px',
    }
}));

const MetaInfo = (props) => {
    const classes = useStyles();

    const dispatch = useDispatchReducerContext();
    const storeState = useStateReducerContext();
    const [copyTooltip, setCopyTooltip] = useState('Copy');

    const projectName = props.projectName;
    const gitLink = `git clone codecommit::eu-west-1://${projectName}`;

    const isBookmarked = useMemo(() => {
        return storeState.bookmarks.includes(projectName);
    }, [projectName, storeState.bookmarks]);

    const handleBookmarkClick = () => {
        dispatch({
            type: isBookmarked ? REMOVE_BOOKMARK : ADD_BOOKMARK,
            payload: {
                bookmark: projectName
            }
        });
    }

    const handleCopy = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(gitLink);
            setCopyTooltip('Copied');
        }
    }

    return <>
        <Box className={classes.projectinfotitle}>
            <Typography className={classes['projectinfo-name']}>
                Pipeline/Repository Name
            </Typography>
            <Typography className={classes['projectinfo-area']}>
                Product Area
            </Typography>
            <Typography className={classes['aws-account']}>
                AWS Account
            </Typography>
            <Typography className={classes['projectinfo-url']}>
                Clone URL
            </Typography>
        </Box>
        <Box className={classes.projectinfocontent}>
            <Typography className={classes['projectinfo-name-content']}>
                {projectName}
                <CommonTooltip title={isBookmarked ? 'Bookmarked' : 'Bookmark'}>
                    <IconButton onClick={handleBookmarkClick} className={classes['icon-btn']}>
                        <img src={isBookmarked ? bookmarkedIcon : unbookmarkedIcon} alt='bookmark' className={classes.bookmarkIcon} />
                    </IconButton>
                </CommonTooltip>
            </Typography>
            <Box className={classes['projectinfo-area-content']}>
                {
                    props.isLoading ? <CircularProgress className={classes['area-loading']} /> : <Typography>{props.owner && props.owner !== '' ? props.owner : '-'}</Typography>
                }
            </Box>
            <Box className={classes['aws-account-content']}>
                {
                    props.isLoading ? <CircularProgress className={classes['area-loading']} /> : <Typography>{props.isDataLakeAccount ? 'Data lake account' : 'Main account'}</Typography>
                }
            </Box>
            <Typography className={classes['projectinfo-url-content']}>
                {gitLink}

                <CommonTooltip title={copyTooltip}>
                    <IconButton onClick={handleCopy} onMouseEnter={() => setCopyTooltip('Copy')} className={classes['icon-btn']}>
                        <img src={copyIcon} alt='copy' className={classes.copyIcon} />
                    </IconButton>
                </CommonTooltip>
            </Typography>
        </Box>
    </>
}

export default MetaInfo;