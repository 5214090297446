import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  CircularProgress,
  withStyles,
} from "@material-ui/core";
import AWS from "aws-sdk";
import Database from "./Database";
import NoData from "../../components/ui/NoData";
import "./dataModel"; // Adjust the path to match the relative location of dataModel.js
import {
  cb2Promise,
  useGetAssumedKeysByAccountIdAndRole,
  useHandleAwsAuthErr,
  ACCOUNT_MAP,
  COST_ROLENAME,
} from "../../common/AwsUtil";
import Decending from '../../assets/icons/sort_decending.png';

const useStyles = makeStyles(() => ({
  container: {
    padding: "24px 70px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#ECECEC",
    padding: "10px 20px",
    borderRadius: "4px",
  },
  title: {
    color: "#4C4948",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    fontFamily: "Roboto",
    backgroundColor: "#ECECEC",
  },
  description: {
    color: "#4C4948",
    fontSize: "12px",
    fontWeight: 300,
    lineHeight: "18px",
    fontFamily: "Roboto",
    backgroundColor: "#ECECEC",
    marginTop: "5px",
  },
  accordionSummary: {
    backgroundColor: "#ECECEC",
    borderRadius: "4px",
  },
  accordion: {
    marginBottom: "16px", // Add padding between the databases
  },
  fontSize: {
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "20px",
    fontFamily: "Roboto",
  },
  expandIcon: {
    transition: "transform 0.3s",
    width: "20px",
    height: "20px",
  },
  expandedIcon: {
    transform: "rotate(180deg)", // Down arrow
  },
  collapsedIcon: {
    transform: "rotate(180deg)", // Up arrow
  },
  nodata: {
    marginTop: "60px",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "30vh",
  },
}));

const OrangeCircularProgress = withStyles({
  root: {
    color: "#E66400",
  },
})(CircularProgress);

const Datalakecatalog = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [expanded, setExpanded] = useState(null);
  const handleAwsAuthErr = useHandleAwsAuthErr();
  const getAssumedKeysByAccountIdAndRole = useGetAssumedKeysByAccountIdAndRole();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const params = await getAssumedKeysByAccountIdAndRole(
          ACCOUNT_MAP.DL_PROD.id,
          COST_ROLENAME
        );
        const s3 = new AWS.S3(params);

        const s3Params = {
          Bucket: `datalake-catalog.edlprod.eu-west-1.udtrucks.net`,
          Key: "glue-catalog.json",
        };
        const data = await cb2Promise(s3, "getObject", s3Params);
        const jsonData = JSON.parse(data.Body.toString("utf-8"));
        setData(jsonData);
        setLoading(false);
      } catch (err) {
        if (!handleAwsAuthErr(err)) {
          console.error("Error fetching data:from S3: ", err);
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [getAssumedKeysByAccountIdAndRole, handleAwsAuthErr]);

  const handleAccordionChange = (databaseName) => (event, isExpanded) => {
    setExpanded(isExpanded ? databaseName : null);
  };

  return (
    <Box className={classes.container}>
      {loading ? (
        <Box className={classes.loader}>
          <OrangeCircularProgress size={50} />
        </Box>
      ) : Object.keys(data).length === 0 ? (
        <NoData
          className={classes.nodata}
          title="No Data Available"
          subTitle="Please check the data source."
        />
      ) : (
        Object.keys(data).map((databaseName) => (
          <Accordion
            key={databaseName}
            expanded={expanded === databaseName}
            onChange={handleAccordionChange(databaseName)}
            className={classes.accordion} // Apply padding between databases
          >
            <AccordionSummary
              expandIcon={
                <img
                  src={Decending}
                  alt="Expand More"
                  className={`${classes.expandIcon} ${
                    expanded === databaseName
                      ? classes.expandedIcon
                      : classes.collapsedIcon
                  }`}
                />
              }
              className={classes.accordionSummary}
            >
              <Box>
                <Typography className={classes.title}>
                  <strong>Database : {databaseName}</strong>
                </Typography>
                {data[databaseName].description && (
                  <Typography className={classes.title}>
                    {data[databaseName].description}
                  </Typography>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Database
                loading={loading}
                data={data[databaseName].tables}
                fontSizeClass={classes.fontSize}
              />
            </AccordionDetails>
          </Accordion>
        ))
      )}
    </Box>
  );
};

export default Datalakecatalog;
