import React, { useState, useEffect, useMemo, useRef } from 'react';
import Decimal from 'decimal.js';

import Box from '@material-ui/core/Box';
import AWS from "aws-sdk";
import { makeStyles } from '@material-ui/core';
import ParamsArea from './ParamsArea';
import { COST_ACCOUNT, COST_ROLENAME, cb2Promise, useGetAssumedKeysByAccountIdAndRole, useHandleAwsAuthErr } from '../../common/AwsUtil';
import moment from 'moment';
import { useDispatchReducerContext } from '../../store';
import { SHOW_TOAST } from '../../store/dispatchNames';
import CostSummary from './CostSummary';
import CostGraph from './CostGraph';
import CostTable from './CostTable';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        gap: '26px',
        width: '100%'
    },
    mainContainer: {
        flex: 'auto',
        overflow: 'hidden'
    },
    paramsContainer: {
        width: '350px',
        flexShrink: 0
    }
}));

export const useCostData = (params, isStartLoad = true) => {
    const getAssumedKeysByAccountIdAndRole = useGetAssumedKeysByAccountIdAndRole();
    const dispatch = useDispatchReducerContext();
    const handleAwsAuthErr = useHandleAwsAuthErr();
    const [costData, setCostData] = useState(null);
    const [costLoading, setCostLoading] = useState(false);
    const [hadInitCeParams, setHadInitCeParams] = useState(false);

    const ceCallKey = useRef();

    useEffect(() => {
        const initCeParams = async () => {
            try {
                await Promise.all(COST_ACCOUNT.map(item => {
                    const call = async () => {
                        try {
                            await getAssumedKeysByAccountIdAndRole(item.id, COST_ROLENAME);
                        } catch (err) {
                            if (err.name !== 'AccessDenied') {
                                throw err;
                            }
                        }
                    };

                    return call();
                }));
                setHadInitCeParams(true);
            } catch (err) {
                if (!handleAwsAuthErr(err)) {
                    dispatch({
                        type: SHOW_TOAST,
                        payload: {
                            message: err.message,
                            severity: 'error'
                        }
                    });
                    return [];
                }
            }

        }
        isStartLoad && !hadInitCeParams && initCeParams();
    }, [dispatch, getAssumedKeysByAccountIdAndRole, handleAwsAuthErr, isStartLoad, hadInitCeParams]);

    const ceParams = useMemo(() => {
        const filters = Object.entries(params.filters).filter(([, value]) => {
            return value.values.length > 0;
        }).map(([key, value]) => {
            if (['service', 'instanceType'].includes(key)) {
                let pKey = '';
                switch (key) {
                    case 'service':
                        pKey = "SERVICE"
                        break;
                    case 'instanceType':
                        pKey = "INSTANCE_TYPE"
                        break;
                    default:
                        break;
                }
                const param = {
                    Dimensions: {
                        Key: pKey,
                        Values: value.values,
                    }
                }
                if (value.condition === 'Includes') {
                    return param;
                } else {
                    return {
                        Not: param
                    };
                }
            } else {
                let pKey = '';
                switch (key) {
                    case 'team':
                        pKey = "Owner"
                        break;
                    case 'component':
                        pKey = "ProjectName"
                        break;
                    case 'env':
                        pKey = "Env"
                        break;
                    default:
                        break;
                }
                const param = value.values.length > 1 ? {
                    Or: value.values.map(item => {
                        return {
                            Tags: {
                                Key: pKey,
                                [item === '' ? 'MatchOptions' : 'Values']: [item === '' ? 'ABSENT' : item]
                            }
                        }
                    })
                } : {
                    Tags: {
                        Key: pKey,
                        [value.values[0] === '' ? 'MatchOptions' : 'Values']: [value.values[0] === '' ? 'ABSENT' : value.values[0]]
                    }
                }
                if (value.condition === 'Includes') {
                    return param;
                } else {
                    return {
                        Not: param
                    };
                }
            }
        })
        let Filter = undefined;
        if (filters.length === 1) {
            Filter = filters[0]
        } else if (filters.length > 1) {
            Filter = {
                And: filters
            }
        }
        return {
            GroupBy: [
                {
                    Key: params.dimension,
                    Type: ['INSTANCE_TYPE', 'SERVICE'].includes(params.dimension) ? 'DIMENSION' : 'TAG'
                }
            ],
            Granularity: params.dateParam.granularity,
            Metrics: [
                'UnblendedCost'
            ],
            TimePeriod: {
                End: moment(params.dateParam.endDate).add(1, 'day').format('YYYY-MM-DD'),
                Start: moment(params.dateParam.startDate).format('YYYY-MM-DD'),
            },
            Filter
        }
    }, [params]);

    useEffect(() => {
        const fetchCEData = async () => {
            if (ceParams.dimension === 'None') {
                setCostData(null);
                return;
            }
            setCostLoading(true);
            try {
                const callKey = new Date().getMilliseconds();
                ceCallKey.current = callKey;
                const detailedDatas = await Promise.all(COST_ACCOUNT.map(item => {
                    const call = async () => {
                        try {
                            const awsCeParams = await getAssumedKeysByAccountIdAndRole(item.id, COST_ROLENAME);
                            const costexplorer = new AWS.CostExplorer(awsCeParams);
                            let NextPageToken = undefined;
                            let result = null;
                            do {
                                const res = await cb2Promise(costexplorer, 'getCostAndUsage', { ...ceParams, NextPageToken });

                                if (!result) {
                                    result = res;
                                } else {
                                    result.ResultsByTime = result.ResultsByTime.concat(res.ResultsByTime);
                                }
                                NextPageToken = res.NextPageToken;
                            } while (NextPageToken)

                            return result;
                        } catch (err) {
                            if (err.name === 'AccessDenied') {
                                return null
                            }
                            throw err;
                        }
                    };

                    return call();
                }));

                if (ceCallKey.current !== callKey) {
                    return;
                }

                setCostData(mergeCost(detailedDatas));
                setCostLoading(false);
            } catch (err) {
                setCostLoading(false);
                if (!handleAwsAuthErr(err)) {
                    dispatch({
                        type: SHOW_TOAST,
                        payload: {
                            message: err.message,
                            severity: 'error'
                        }
                    });
                }
            }
        }
        isStartLoad && hadInitCeParams && fetchCEData();
    }, [hadInitCeParams, getAssumedKeysByAccountIdAndRole, handleAwsAuthErr, isStartLoad, dispatch, ceParams]);

    return {
        costData,
        costLoading,
        hadInitCeParams
    }
}

const mergeCost = (costDatas) => {
    costDatas = costDatas.filter(item => item);
    const mergedData = {
        DimensionValueAttributes: JSON.parse(JSON.stringify(costDatas[0].DimensionValueAttributes)),
        ResultsByTime: []
    };

    if (costDatas[0].GroupDefinitions) {
        mergedData.GroupDefinitions = JSON.parse(JSON.stringify(costDatas[0].GroupDefinitions))
    }

    return costDatas.reduce((total, item) => {
        item.ResultsByTime.forEach(item => {
            const totalItem = total.ResultsByTime.find(totalItem => {
                return totalItem.TimePeriod.End === item.TimePeriod.End && totalItem.TimePeriod.Start === item.TimePeriod.Start;
            });
            if (totalItem) {
                if (item.Total.UnblendedCost) {
                    if (totalItem.Total.UnblendedCost) {
                        totalItem.Total.UnblendedCost.Amount = Decimal.add(item.Total.UnblendedCost.Amount, totalItem.Total.UnblendedCost.Amount).toString();
                    } else {
                        totalItem.Total.UnblendedCost = { ...item.Total.UnblendedCost };
                    }
                }

                if (item.Groups) {
                    item.Groups.forEach((groupItem) => {
                        const totalGroupItem = totalItem.Groups.find(internalTotalGroupItem => {
                            return internalTotalGroupItem.Keys.toString() === groupItem.Keys.toString();
                        });

                        if (totalGroupItem) {
                            totalGroupItem.Metrics.UnblendedCost.Amount = Decimal.add(groupItem.Metrics.UnblendedCost.Amount, totalGroupItem.Metrics.UnblendedCost.Amount).toString();
                        } else {
                            totalItem.Groups.push(JSON.parse(JSON.stringify(groupItem)));
                        }
                    });
                }
            } else {
                total.ResultsByTime.push(JSON.parse(JSON.stringify(item)));
            }
        });
        return total;
    }, mergedData);
}

const Costreport = () => {
    const classes = useStyles();

    const [params, setParams] = useState({
        dateParam: {
            startDate: moment().subtract(5, 'month').startOf('month').toDate(),
            endDate: moment().subtract(1, 'day').toDate(),
            granularity: 'MONTHLY'
        },
        dimension: 'SERVICE',
        filters: {
            team: {
                condition: 'Includes',
                values: []
            },
            service: {
                condition: 'Includes',
                values: []
            },
            component: {
                condition: 'Includes',
                values: []
            },
            env: {
                condition: 'Includes',
                values: []
            },
            instanceType: {
                condition: 'Includes',
                values: []
            }
        }
    });

    const {
        costData,
        costLoading,
        hadInitCeParams
    } = useCostData(params);

    return <Box className={classes.container}>
        {
            hadInitCeParams && <>
                <Box className={classes.mainContainer}>
                    <CostSummary generalData={costData} loading={costLoading} granularity={params.dateParam.granularity} />
                    <CostGraph detailedData={costData} loading={costLoading} granularity={params.dateParam.granularity} />
                    <CostTable detailedData={costData} loading={costLoading} granularity={params.dateParam.granularity} dimension={params.dimension} />
                </Box>
                <Box className={classes.paramsContainer}>
                    <ParamsArea value={params} onChange={setParams} />
                </Box>
            </>
        }
    </Box>
}

export default Costreport;