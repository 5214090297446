import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AWS from "aws-sdk";

import {
    Box, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, Popover, Radio, RadioGroup
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import removeFilterIcon from '../../../assets/icons/remove_filter.svg';
import filterIcon from '../../../assets/icons/filter.svg';
import searchIcon from '../../../assets/icons/search.svg';
import closeCircleIcon from '../../../assets/icons/cross_circle_orange.svg';
import CommonTextField from '../../../components/ui/CommonTextField';
import SecondaryButton from '../../../components/ui/SecondaryButton';
import applyIcon from '../../../assets/icons/apply.svg';
import closePic from '../../../assets/icons/black_close.svg';
import { cb2Promise, useGetAssumedKeysByAccountIdAndRole, useHandleAwsAuthErr } from '../../../common/AwsUtil';
import moment from 'moment';
import { SHOW_TOAST } from '../../../store/dispatchNames';
import { useDispatchReducerContext } from '../../../store';
import { COST_ACCOUNT, COST_ROLENAME } from '../../../common/AwsUtil';
import { removeDuplicate } from '../../../common/Util';
import Add from '@material-ui/icons/Add';
import CancelButton from '../../../components/ui/CancelButton';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    itemContainer: {
        position: 'relative',
        padding: '6px 8px'
    },
    clear: {
        position: 'absolute',
        fontSize: '14px',
        color: '#E66400',
        cursor: 'pointer',
        right: '10px',
        top: '20px',
        zIndex: 1,
        '& > *': {
            verticalAlign: 'middle'
        }
    },
    filterOptionContainer: {
        maxHeight: '270px',
        overflow: 'auto',
        "& .MuiFormControl-root": {
            display: 'block'
        }
    },
    loading: {
        color: '#D8B200',
        width: '15px !important',
        height: '15px !important'
    },
    titleContainer: {
        position: 'relative',
    },
    title: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#4D4E53',
        '& > *': {
            verticalAlign: 'middle'
        }
    },
    removeIcon: {
        position: 'relative',
        left: '-6px',
        top: '-1px'
    },
    clearAll: {
        position: 'absolute',
        right: '10px',
        top: '4px',
        fontSize: '14px',
        color: '#E66400',
        cursor: 'pointer',
    },
    filterIcon: {
        marginRight: '6px',
        position: 'relative',
        top: '-1px'
    },
    itemsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '6px 12px',
        paddingTop: '4px'
    },
    filterItem: {
        maxWidth: '100%',
        border: '1px solid #E66400',
        padding: '0 8px',
        borderRadius: '12px',
        fontSize: '13px',
        color: '#4C4948',
        lineHeight: '24px',
        fontWeight: 400,
        '& > *': {
            verticalAlign: 'middle'
        }
    },
    removeFilterItemIcon: {
        cursor: 'pointer',
        position: 'relative',
        paddingLeft: '6px',
        top: '-1px'
    },
    removeFilterIcon: {
        cursor: 'pointer'
    },
    showAll: {
        color: '#E66400',
        fontSize: '14px',
        lineHeight: '19px',
        fontWeight: 400,
        cursor: 'pointer',
        marginTop: '4px',
        display: 'inline-block',
        '& > *': {
            verticalAlign: 'middle'
        }
    },
    showAllIcon: {
        fontSize: '14px',
        position: 'relative',
        top: '-1px'
    },
    textField: {
        '& .MuiFormLabel-root': {
            fontSize: '14px',
            fontWeight: 400
        },
        '& .MuiInputBase-input': {
            paddingLeft: '8px'
        }
    },
    filterInput: {
        width: '100%',
        border: '1px solid #E66400',
        fontSize: '15px',
        height: '35px',
        boxSizing: 'border-box',
        paddingLeft: '34px',
        '&:focus': {
            outline: 'none'
        }
    },
    popover: {
        '& .MuiPaper-rounded': {
            borderRadius: 0
        }
    },
    filterMorePopover: {
        '& .MuiPaper-rounded': {
            borderRadius: 0
        }
    },
    popoverContainer: {
        width: '288px',
        position: 'relative'
    },
    filterLoadingContainer: {
        textAlign: 'center',
        padding: '8px'
    },
    filterItemSearchIcon: {
        position: 'absolute',
        left: '10px',
        top: '8px',
        width: '18px',
        height: '18px'
    },
    radioDivider: {
        backgroundColor: '#C4C4C4',
        margin: '7px 0'
    },
    dividerColor: {
        backgroundColor: '#C4C4C4'
    },
    filterRadioItem: {
        flex: 1,
        textAlign: 'center',
        color: '#4D4E53',
        "& .MuiFormControlLabel-label": {
            fontSize: '14px',
        },
        "& .MuiRadio-colorSecondary, & .MuiRadio-colorSecondary.Mui-checked": {
            color: '#E66400'
        },
    },
    filterCheckboxItem: {
        paddingLeft: '6px',
        margin: 0,
        '&:hover': {
            backgroundColor: '#F7EED7'
        },
        '& .MuiCheckbox-colorSecondary, & .MuiCheckbox-colorSecondary.Mui-checked': {
            color: '#E66400'
        }
    },
    filterMorePopoverContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    filterMorePopoverItemsContainer: {
        padding: '5px 8px',
        maxHeight: '180px',
        overflow: 'auto',
        flex: 1,
        borderRight: '0.5px solid #C4C4C4',

    },
    popoverFilterItem: {
        display: 'flex',
        '& + &': {
            marginTop: '6px'
        }
    },
    filterMorePopoverCloseicon: {
        width: '14px',
        height: '14px',
        margin: '10px',
        cursor: 'pointer'
    },
    filterBtnsContainer: {
        padding: '12px 20px',
        display: 'flex',
        gap: '16px',
        '& button': {
            flex: 1,
        }
    }
}))

const FilterItem = (props) => {
    const classes = useStyles();
    const { title, fetchDataMethod, value, setValue } = props;

    const [searchTxt, setSearchTxt] = useState("");
    const [condition, setCondition] = useState("Includes");

    const [anchorEl, setAnchorEl] = useState(null);
    const [filterMoreOpenAnchorEl, setFilterMoreOpenAnchorEl] = useState(null);

    const handleShowAllItems = (event) => {
        setFilterMoreOpenAnchorEl(event.currentTarget);
    }

    const handlefilterMoreClose = () => {
        setFilterMoreOpenAnchorEl(null);
    };

    const handleClick = (event) => {
        setInternalValue(value.values);
        setCondition(value.condition);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const open = Boolean(anchorEl);
    const filterMoreOpen = Boolean(filterMoreOpenAnchorEl);

    const onClear = () => {
        setValue({
            condition,
            values: []
        });
    }

    const handleApply = () => {
        setValue({
            condition,
            values: internalValue
        });
        handleClose();
    }

    const removeItem = (item) => {
        setValue({
            condition,
            values: value.values.filter(val => val !== item)
        })
    }

    const [internalValue, setInternalValue] = useState([]);
    const handleChange = (e) => {
        if (internalValue.includes(e.target.name) && !e.target.checked) {
            setInternalValue(internalValue.filter(item => item !== e.target.name))
        } else if (!internalValue.includes(e.target.name) && e.target.checked) {
            setInternalValue([...internalValue, e.target.name]);
        }
    }

    const [isLoading, setIsLoading] = useState(false);
    const [allOptions, setAllOptions] = useState([]);

    const options = useMemo(() => {
        return allOptions.filter(option => {
            let handledOption = option === '' ? 'no name' : option;
            return handledOption.toLowerCase().includes(searchTxt.toLowerCase());
        })
    }, [searchTxt, allOptions]);

    useEffect(() => {
        setIsLoading(true);
        setAllOptions([]);
        fetchDataMethod().then((data) => {
            setAllOptions(data);
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        })
    }, [fetchDataMethod]);

    const shownTxt = useMemo(() => {
        if (value.values.length === 0) return '';
        return `${title.toLowerCase().charAt(0).toUpperCase()}${title.slice(1)}${value.values.length === 1 ? '' : 's'} ${value.condition === 'Includes' ? 'included' : 'excluded'} (${value.values.length})`;
    }, [title, value]);



    return <Box className={classes.itemContainer}>
        <Typography className={classes.clear} onClick={onClear}>
            <img src={removeFilterIcon} alt='Time' className={classes.removeIcon} />
            Clear
        </Typography>
        <CommonTextField
            label={title}
            value={shownTxt}
            className={classes.textField}
            onClick={handleClick}
            InputLabelProps={{ shrink: true }}
            placeholder={`Select ${title.toLowerCase()}`}
            margin="normal"
            readOnly
            fullWidth
        >
        </CommonTextField>
        <Box className={classes.itemsContainer}>
            {
                value.values.slice(0, 2).map(item => <Typography key={item} className={classes.filterItem}>
                    {item === '' ? 'No Name' : item}
                    <img src={closeCircleIcon} alt='Remove' onClick={() => removeItem(item)} className={classes.removeFilterItemIcon} />
                </Typography>)
            }
        </Box>
        {
            value.values.length > 2 && <>
                <Typography className={classes.showAll} onClick={handleShowAllItems}><Add className={classes.showAllIcon} />Show all (+{value.values.length - 2})</Typography>
                <Popover open={filterMoreOpen}
                    anchorEl={filterMoreOpenAnchorEl}
                    onClose={handlefilterMoreClose}
                    className={classes.filterMorePopover}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                    <Box className={classes.filterMorePopoverContainer}>
                        <Box className={classes.filterMorePopoverItemsContainer}>
                            {
                                value.values.map(item => <Box key={item} className={classes.popoverFilterItem}><Typography className={classes.filterItem}>
                                    {item === '' ? 'No Name' : item}
                                    <img src={closeCircleIcon} alt='Remove' onClick={() => removeItem(item)} className={classes.removeFilterItemIcon} />
                                </Typography></Box>)
                            }
                        </Box>
                        <Box className={classes.filterMorePopoverCloseContainer}>
                            <img alt='close' className={classes.filterMorePopoverCloseicon} src={closePic} onClick={handlefilterMoreClose} />
                        </Box>
                    </Box>
                </Popover>
            </>
        }
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            className={classes.popover}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Box className={classes.popoverContainer}>
                <img src={searchIcon} alt='Search' className={classes.filterItemSearchIcon} />
                <input
                    autoFocus
                    value={searchTxt}
                    onChange={(e) => setSearchTxt(e.target.value)}
                    className={classes.filterInput}
                    placeholder={`Search ${title.toLowerCase()}`}
                ></input>
                <RadioGroup row value={condition} onChange={(e) => setCondition(e.target.value)}>
                    <Box className={classes.filterRadioItem}>
                        <FormControlLabel value="Includes" control={<Radio />} label="Includes" />
                    </Box>
                    <Divider className={classes.radioDivider} orientation='vertical' flexItem />
                    <Box className={classes.filterRadioItem}>
                        <FormControlLabel value="Excludes" control={<Radio />} label="Excludes" />
                    </Box>
                </RadioGroup>
                <Divider className={classes.dividerColor} />
                <Box className={classes.filterOptionContainer}>
                    {
                        isLoading ? <Box className={classes.filterLoadingContainer}><CircularProgress className={classes.loading} /></Box> : <FormControl>
                            <FormGroup>
                                {
                                    options.map(option => <FormControlLabel
                                        key={option}
                                        className={classes.filterCheckboxItem}
                                        control={<Checkbox checked={internalValue.includes(option)} onChange={handleChange} name={option} />}
                                        label={option === '' ? 'No Name' : option}
                                    />)
                                }
                            </FormGroup>
                        </FormControl>
                    }
                </Box>
                <Divider className={classes.dividerColor} />
                <Box className={classes.filterBtnsContainer}>
                    <CancelButton onClick={handleClose}>Cancel</CancelButton>
                    <SecondaryButton startIcon={<img src={applyIcon} alt='apply' />} onClick={handleApply}>Apply</SecondaryButton>

                </Box>
            </Box>
        </Popover>
    </Box>
}

const FilterParams = (props) => {
    const classes = useStyles();
    const getAssumedKeysByAccountIdAndRole = useGetAssumedKeysByAccountIdAndRole();
    const dispatch = useDispatchReducerContext();
    const handleAwsAuthErr = useHandleAwsAuthErr();
    const { value, onChange } = props;
    const onFilterItemChange = (newItemValue, filterName) => {
        onChange({
            ...value,
            [filterName]: newItemValue
        });
    }

    const fetchTeams = useCallback(async () => {
        const combineCalls = async () => {
            const tagsList = await Promise.all(COST_ACCOUNT.map(item => {
                const call = async () => {
                    try {
                        const ceParams = await getAssumedKeysByAccountIdAndRole(item.id, COST_ROLENAME);
                        const costexplorer = new AWS.CostExplorer(ceParams);
                        const res = await cb2Promise(costexplorer, 'getTags', {
                            TimePeriod: {
                                Start: moment().subtract(12, 'month').startOf('month').format('YYYY-MM-DD'),
                                End: moment().format('YYYY-MM-DD')
                            },
                            TagKey: 'Owner'
                        });
                        return res.Tags;
                    } catch (err) {
                        if (err.name === 'AccessDenied') {
                            return []
                        }
                        throw err;
                    }

                };

                return call();
            }))


            const tagList = tagsList.flat();
            return removeDuplicate(tagList);
        }
        try {
            return await combineCalls();
        } catch (err) {
            if (!handleAwsAuthErr(err)) {
                dispatch({
                    type: SHOW_TOAST,
                    payload: {
                        message: err.message,
                        severity: 'error'
                    }
                });
                return [];
            }
        }

    }, [getAssumedKeysByAccountIdAndRole, dispatch, handleAwsAuthErr]);

    const fetchComponents = useCallback(async () => {
        const combineCalls = async () => {
            const tagsList = await Promise.all(COST_ACCOUNT.map(item => {
                const call = async () => {
                    try {
                        const ceParams = await getAssumedKeysByAccountIdAndRole(item.id, COST_ROLENAME);
                        const costexplorer = new AWS.CostExplorer(ceParams);
                        const res = await cb2Promise(costexplorer, 'getTags', {
                            TimePeriod: {
                                Start: moment().subtract(12, 'month').startOf('month').format('YYYY-MM-DD'),
                                End: moment().format('YYYY-MM-DD')
                            },
                            TagKey: 'ProjectName'
                        });
                        return res.Tags;
                    } catch (err) {
                        if (err.name === 'AccessDenied') {
                            return []
                        }
                        throw err;
                    }
                };

                return call();
            }))


            const tagList = tagsList.flat();
            return removeDuplicate(tagList);
        }

        try {
            return await combineCalls();
        } catch (err) {
            if (!handleAwsAuthErr(err)) {
                dispatch({
                    type: SHOW_TOAST,
                    payload: {
                        message: err.message,
                        severity: 'error'
                    }
                });
                return [];
            }
        }

    }, [getAssumedKeysByAccountIdAndRole, dispatch, handleAwsAuthErr]);

    const fetchEnvs = useCallback(async () => {
        const combineCalls = async () => {
            const tagsList = await Promise.all(COST_ACCOUNT.map(item => {
                const call = async () => {
                    try {
                        const ceParams = await getAssumedKeysByAccountIdAndRole(item.id, COST_ROLENAME);
                        const costexplorer = new AWS.CostExplorer(ceParams);
                        const res = await cb2Promise(costexplorer, 'getTags', {
                            TimePeriod: {
                                Start: moment().subtract(12, 'month').startOf('month').format('YYYY-MM-DD'),
                                End: moment().format('YYYY-MM-DD')
                            },
                            TagKey: 'Env'
                        });
                        return res.Tags;
                    } catch (err) {
                        if (err.name === 'AccessDenied') {
                            return []
                        }
                        throw err;
                    }
                };

                return call();
            }))


            const tagList = tagsList.flat();
            return removeDuplicate(tagList);
        }

        try {
            return await combineCalls();
        } catch (err) {
            if (!handleAwsAuthErr(err)) {
                dispatch({
                    type: SHOW_TOAST,
                    payload: {
                        message: err.message,
                        severity: 'error'
                    }
                });
                return [];
            }
        }

    }, [getAssumedKeysByAccountIdAndRole, dispatch, handleAwsAuthErr]);

    const fetchServices = useCallback(async () => {
        const combineCalls = async () => {
            const servicesList = await Promise.all(COST_ACCOUNT.map(item => {
                const call = async () => {
                    try {
                        const ceParams = await getAssumedKeysByAccountIdAndRole(item.id, COST_ROLENAME);
                        const costexplorer = new AWS.CostExplorer(ceParams);
                        const res = await cb2Promise(costexplorer, 'getDimensionValues', {
                            TimePeriod: {
                                Start: moment().subtract(12, 'month').startOf('month').format('YYYY-MM-DD'),
                                End: moment().format('YYYY-MM-DD')
                            },
                            Dimension: 'SERVICE'
                        });
                        return res.DimensionValues.map(item => item.Value);
                    } catch (err) {
                        if (err.name === 'AccessDenied') {
                            return []
                        }
                        throw err;
                    }
                };

                return call();
            }))


            const serviceList = servicesList.flat();
            return removeDuplicate(serviceList);
        }

        try {
            return await combineCalls();
        } catch (err) {
            if (!handleAwsAuthErr(err)) {
                dispatch({
                    type: SHOW_TOAST,
                    payload: {
                        message: err.message,
                        severity: 'error'
                    }
                });
                return [];
            }
        }

    }, [getAssumedKeysByAccountIdAndRole, dispatch, handleAwsAuthErr]);

    const fetchInstanceType = useCallback(async () => {
        const combineCalls = async () => {
            const servicesList = await Promise.all(COST_ACCOUNT.map(item => {
                const call = async () => {
                    try {
                        const ceParams = await getAssumedKeysByAccountIdAndRole(item.id, COST_ROLENAME);
                        const costexplorer = new AWS.CostExplorer(ceParams);
                        const res = await cb2Promise(costexplorer, 'getDimensionValues', {
                            TimePeriod: {
                                Start: moment().subtract(12, 'month').startOf('month').format('YYYY-MM-DD'),
                                End: moment().format('YYYY-MM-DD')
                            },
                            Dimension: 'INSTANCE_TYPE'
                        });
                        return res.DimensionValues.map(item => item.Value);
                    } catch (err) {
                        if (err.name === 'AccessDenied') {
                            return []
                        }
                        throw err;
                    }
                };

                return call();
            }))


            const serviceList = servicesList.flat();
            return removeDuplicate(serviceList);
        }

        try {
            return await combineCalls();
        } catch (err) {
            if (!handleAwsAuthErr(err)) {
                dispatch({
                    type: SHOW_TOAST,
                    payload: {
                        message: err.message,
                        severity: 'error'
                    }
                });
                return [];
            }
        }

    }, [getAssumedKeysByAccountIdAndRole, dispatch, handleAwsAuthErr]);

    const clearAll = () => {
        onChange(Object.keys(value).reduce((total, key) => {
            total[key] = {
                condition: 'Includes',
                values: []
            };
            return total;
        }, {}));
    }

    return <Box>
        <Box className={classes.titleContainer}>
            <Typography className={classes.title}>
                <img src={filterIcon} alt='Time' className={classes.filterIcon} />
                Filters
            </Typography>
            <Typography className={classes.clearAll} onClick={clearAll}>
                <img src={removeFilterIcon} alt='Time' className={classes.removeIcon} />
                Clear all
            </Typography>
        </Box>

        <Box>
            <FilterItem title="Product Owner" value={value.team} setValue={(itemVal) => onFilterItemChange(itemVal, 'team')} fetchDataMethod={fetchTeams}></FilterItem>
            <FilterItem title="AWS Service" value={value.service} setValue={(itemVal) => onFilterItemChange(itemVal, 'service')} fetchDataMethod={fetchServices}></FilterItem>
            <FilterItem title="Component" value={value.component} setValue={(itemVal) => onFilterItemChange(itemVal, 'component')} fetchDataMethod={fetchComponents}></FilterItem>
            <FilterItem title="Environment" value={value.env} setValue={(itemVal) => onFilterItemChange(itemVal, 'env')} fetchDataMethod={fetchEnvs}></FilterItem>
            <FilterItem title="Instance Type" value={value.instanceType} setValue={(itemVal) => onFilterItemChange(itemVal, 'instanceType')} fetchDataMethod={fetchInstanceType}></FilterItem>
        </Box>
    </Box>
}

export default FilterParams;
