import React, { useMemo } from 'react';

import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { styled } from "@material-ui/core/styles";

const StyledCancelButton = styled(Button)({
    boxShadow: 'none',
    fontSize: 14,
    textTransform: 'none',
    fontWeight: 600,
    border: '1px solid',
    backgroundColor: '#FFFFFF',
    borderColor: '#4C4948',
    paddingLeft: '28px',
    paddingRight: '28px',
    color: '#4C4948',
    '&:hover': {
        backgroundColor: '#ECECEC',
        borderColor: '#4C4948',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#ECECEC',
        borderColor: '#4C4948',
    },
    '&:focus': {
        backgroundColor: '#ECECEC',
        borderColor: '#4C4948',
    },
    '&.Mui-disabled': {
        color: '#CCCCCC',
        background: '#FFFFFF',
        borderColor: '#CCCCCC',
    }
});

const CancelButton = (props) => {

    const disabled = useMemo(() => {
        if (props.loading) return true;
        return props.disabled
        
    }, [props.loading, props.disabled])

    const startIcon = useMemo(() => {
        if (props.loading) return <CircularProgress size={18} color='inherit'/>
        return props.startIcon;
    }, [props.loading, props.startIcon]);
    
    return <StyledCancelButton {...props} loading={undefined} disabled={disabled} startIcon={startIcon}>
        {props.children}
    </StyledCancelButton>;
};

export default CancelButton;
