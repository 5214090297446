import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AWS from 'aws-sdk';
import Grid from "@material-ui/core/Grid";
import { cb2Promise, useGetAssumedKeysForDeployAccounts, useHandleAwsAuthErr } from "../../common/AwsUtil";
import { useDispatchReducerContext } from "../../store";
import { SHOW_TOAST } from "../../store/dispatchNames";
import { CommonDialog, CommonDialogActions, CommonDialogContent, CommonDialogTitle } from "../../components/ui/CommonDialog";
import CancelButton from "../../components/ui/CancelButton";
import CommonButton from "../../components/ui/CommonButton";
import CommonMenuItem from "../../components/ui/CommonMenuItem";
import CommonTextField from "../../components/ui/CommonTextField";

import refreshIcon from '../../assets/icons/refresh_white.svg';


const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      width: '500px'
    }
  },
  title: {
    textAlign: 'center'
  },
  content: {
    height: '130px',
    padding: '30px 124px 50px'
  },
  btnsArea: {
    textAlign: 'center',
    margin: '0 auto'
  },
  refreshIcon: {
    width: '18px',
    height: '18px',
    position: 'relative',
    top: '-1px'
  }
}));

// props: isOpen, onClose, projectShortName, awsAccessKey, secretAccessKey, pipelineOwner, env

const RefreshInstanceDialog = (props) => {
  const classes = useStyles();
  const handleAwsAuthErr = useHandleAwsAuthErr();
  const getAssumedKeysForDeployAccounts = useGetAssumedKeysForDeployAccounts();
  const dispatch = useDispatchReducerContext();

  const { pipelineOwner, env, isOpen } = props;

  const AutoscalingForRead = useRef();
  const AutoscalingForWrite = useRef();
  const EC2 = useRef();
  const handleClose = useCallback(() => {
    props.onClose()
  }, [props]);

  const launchTemplateName = useMemo(() => {
    return props.projectShortName + "-lt-" + props.env;
  }, [props])
  const asgGroupName = useMemo(() => {
    return props.projectShortName + "-asg-" + props.env;
  }, [props])

  const [launchTemplateNames, setLaunchTemplateName] = useState([])
  const [versionList, setVersionList] = useState([])

  const [isRefreshLoading, setIsRefreshLoading] = useState(false)

  const [formLaunchTemplateName, setFormLaunchTemplateName] = useState('')
  const [formVersion, setFormVersion] = useState('')

  // init data --- start
  useEffect(() => {
    const initData = async () => {
      if (!isOpen) return;
      setFormLaunchTemplateName('')
      setFormVersion('')

      try {
        const assumedKeysForRead = await getAssumedKeysForDeployAccounts(pipelineOwner, env, 'r');
        AutoscalingForRead.current = new AWS.AutoScaling(assumedKeysForRead);
        EC2.current = new AWS.EC2(assumedKeysForRead);

        const launchTemplates = await cb2Promise(EC2.current, 'describeLaunchTemplates', {});
        setLaunchTemplateName(launchTemplates.LaunchTemplates)
        if (launchTemplates.LaunchTemplates.find(item => item.LaunchTemplateName === launchTemplateName)) {
          setFormLaunchTemplateName(launchTemplateName)
        }
      } catch (err) {
        if (!handleAwsAuthErr(err)) {
          setFormLaunchTemplateName('')
          dispatch({
            type: SHOW_TOAST,
            payload: {
              message: err.message,
              severity: 'error'
            }
          });
        }
      }
    }

    initData();

  }, [isOpen, env, pipelineOwner, launchTemplateName, getAssumedKeysForDeployAccounts, handleAwsAuthErr, dispatch])

  useEffect(() => {
    const initVersion = async () => {
      if (!EC2.current || !formLaunchTemplateName || formLaunchTemplateName === '') return;

      try {
        const data = await cb2Promise(EC2.current, 'describeLaunchTemplateVersions', { LaunchTemplateName: formLaunchTemplateName });
        setVersionList(data.LaunchTemplateVersions)
        setFormVersion(data.LaunchTemplateVersions[0].VersionNumber)
      } catch (err) {
        if (!handleAwsAuthErr(err)) {
          setFormVersion('')
          dispatch({
            type: SHOW_TOAST,
            payload: {
              message: err.message,
              severity: 'error'
            }
          });
        }
      }
    }

    initVersion();

  }, [formLaunchTemplateName, dispatch, handleAwsAuthErr])
  // init data --- end

  // refresh method --- start
  const handleRefreshInstance = useCallback(async () => {
    if (!AutoscalingForRead.current) return;
    
    try {
      const assumedKeysForWrite = await getAssumedKeysForDeployAccounts(pipelineOwner, env, 'w');
      AutoscalingForWrite.current = new AWS.AutoScaling(assumedKeysForWrite);

      setIsRefreshLoading(true);

      const instanceRefreshs = await cb2Promise(AutoscalingForRead.current, 'describeInstanceRefreshes', {
        AutoScalingGroupName: asgGroupName,
      })
      const isInProgess = !!instanceRefreshs.InstanceRefreshes.find(item => {
        return ["Pending", "InProgress", "Cancelling", "RollbackInProgress"].includes(item.Status)
      })

      if (isInProgess) {
        dispatch({
          type: SHOW_TOAST,
          payload: {
            message: "The last instance refresh is not completed yet, please wait for it to get completed before starting instance refresh again",
            severity: 'warning'
          }
        });
        setIsRefreshLoading(false);
        return;
      }

      const params = {
        AutoScalingGroupName: asgGroupName,
        Preferences: {
          InstanceWarmup: 0,
          MinHealthyPercentage: 90
        }
      };

      if (formLaunchTemplateName && formLaunchTemplateName !== '' && formVersion && formVersion !== '') {
        params.DesiredConfiguration = {
          LaunchTemplate: {
            LaunchTemplateName: formLaunchTemplateName,
            Version: formVersion + ""
          }
        }
      }

      await cb2Promise(AutoscalingForWrite.current, 'startInstanceRefresh', params);
      props.onClose()
      setTimeout(() => {
        props.onRefreshSuccess();
      }, 2000);
      dispatch({
        type: SHOW_TOAST,
        payload: {
          message: "Refresh instance triggered successfully, it will take 10-15 to complete",
          severity: 'success'
        }
      });
    } catch (err) {
      if (!handleAwsAuthErr(err)) {
        dispatch({
          type: SHOW_TOAST,
          payload: {
            message: err.message,
            severity: 'error'
          }
        });
      }
    } finally {
      setIsRefreshLoading(false);
    }

  }, [props, asgGroupName, formLaunchTemplateName, formVersion, dispatch, handleAwsAuthErr, env, pipelineOwner, getAssumedKeysForDeployAccounts]);
  // refresh method --- end

  const validate = () => {
    const hasLaunchTemplateName = formLaunchTemplateName && formLaunchTemplateName !== '';
    const hasVersion = formVersion && formVersion !== '';
    if (hasLaunchTemplateName) {
      return hasVersion;
    }
    return true;
  };

  return <>
    <CommonDialog
      open={props.isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      disableEscapeKeyDown
      className={classes.dialog}
    >
      <CommonDialogTitle className={classes.title}>Refresh Instance (Autoscaling Group)</CommonDialogTitle>
      <CommonDialogContent className={classes.content}>
        <form noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CommonTextField
                select
                label="Launch template"
                value={formLaunchTemplateName}
                onChange={(e) => setFormLaunchTemplateName(e.target.value)}
                disabled={true}
                fullWidth
              >
                {launchTemplateNames.map((item) => <CommonMenuItem key={item.LaunchTemplateName} value={item.LaunchTemplateName}>{item.LaunchTemplateName}</CommonMenuItem>)}
              </CommonTextField>
            </Grid>
            <Grid item xs={12}>
              <CommonTextField
                select
                label="Version"
                value={formVersion}
                onChange={(e) => setFormVersion(e.target.value)}
                fullWidth
              >
                {versionList.map((item, index) => <CommonMenuItem key={item.VersionNumber} value={item.VersionNumber}>
                  {item.VersionNumber}{item.DefaultVersion ? ' (Default)' : ''}{index === 0 ? ' (Latest)' : ''}
                </CommonMenuItem>)}
              </CommonTextField>
            </Grid>
          </Grid>
        </form>
      </CommonDialogContent>
      <CommonDialogActions className={classes.btnsArea}>
        <CancelButton onClick={handleClose}>Cancel</CancelButton>
        <CommonButton onClick={handleRefreshInstance} color="primary" startIcon={<img src={refreshIcon} className={classes.refreshIcon} alt="refresh" />} disabled={!validate()} loading={isRefreshLoading} autoFocus>
          Refresh
        </CommonButton>
      </CommonDialogActions>
    </CommonDialog>
  </>
}

export default RefreshInstanceDialog;
