import React from 'react';
import { NavLink } from "react-router-dom";

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import bookmarksIcon from '../../assets/icons/bookmarks.png';
import pipelinehistoryIcon from '../../assets/icons/pipelinehistory.png';
import pipelinesIcon from '../../assets/icons/pipelines.png';
import costIcon from '../../assets/icons/service_cost.svg';
import datalakecatalogIcon from '../../assets/icons/data_catalog.png';
 


const useStyles = makeStyles((theme) => ({
    tabContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    tab: {
        color: '#4C4948',
        fontFamily: 'Roboto',
        fontSize: '18px',
        fontWeight: 400,
        borderBottom: '0.5px solid #4C4948',
        padding: '8px 24px',
        display: 'block',
        position: 'relative',
        '& > *': {
            verticalAlign: 'middle'
        },
        '&::before': {
            content: '""',
            display: 'block',
            width: '0.5px',
            height: '30px',
            background: '#4C4948',
            position: 'absolute',
            left: 0,
            bottom: '4px'
        },
        '&:last-of-type::after': {
            content: '""',
            display: 'block',
            width: '0.5px',
            height: '30px',
            background: '#4C4948',
            position: 'absolute',
            right: 0,
            bottom: '4px'
        }
    },
    disabledTab: {
        pointerEvents: 'none',
        cursor: 'none'
    },
    restArea: {
        flex: 1,
        borderBottom: '0.5px solid #4C4948',
    },
    activeTab: {
        background: '#F7EED7',
        border: '0.5px solid #4C4948',
        borderBottom: 'none',
        fontWeight: 600,
        '&::after': {
            width: '0 !important'
        },
        '&::before': {
            width: '0 !important'
        },
        '& + a::before': {
            width: '0 !important'
        }
    },
    tabIcon: {
        width: '24px',
        height: '24px'
    }
}));

const HomeNav = () => {
    const classes = useStyles();

    return <Box className={classes.tabContainer}>
        <NavLink className={classes.tab} activeClassName={classes.activeTab} to="/bookmarks">
            <img src={bookmarksIcon} alt='Bookmarks' className={classes.tabIcon} /> Bookmarks
        </NavLink>
        <NavLink className={classes.tab} activeClassName={classes.activeTab} to="/pipelines">
            <img src={pipelinesIcon} alt='Pipelines' className={classes.tabIcon} /> Pipelines
        </NavLink>
        <NavLink className={classes.tab + ' ' + classes.disabledTab} activeClassName={classes.activeTab} to="/project">
            <img src={pipelinehistoryIcon} alt='Pipeline Detail/History' className={classes.tabIcon} /> Pipeline Detail/History
        </NavLink>
        <NavLink className={classes.tab} activeClassName={classes.activeTab} to="/costreport">
            <img src={costIcon} alt='Cost Dashboard' className={classes.tabIcon} /> Cost Dashboard
        </NavLink>
        <NavLink className={classes.tab} activeClassName={classes.activeTab} to="/datalakecatalog">
            <img src={datalakecatalogIcon} alt='Datalake Catalog' className={classes.tabIcon} /> Datalake Catalog
        </NavLink>
        <div className={classes.restArea}></div>
    </Box>;
}

export default HomeNav;