import React from 'react';

import { useStateReducerContext } from '../../store';

import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 10000
    },
    loading: {
        color: '#E66400',
    }
}))

const AppLoading = () => {
    const store = useStateReducerContext();
    const classes = useStyles();

    return <Backdrop
    className={classes['backdrop']}
    open={store.appLoading}
>
    <CircularProgress className={classes['loading']} />
</Backdrop>;
}

export default AppLoading;