import React from 'react';

import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        color: '#4C4948',
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '20px',
        background: '#ECECEC',
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '0.5px solid #CCCCCC'
    },
    actionName: {
        fontWeight: 700,
        padding: '10px 0 10px 56px',
        fontSize: '13px',
        lineHeight: '20px',
        flex: 1,
        borderRight: '0.5px solid #CCCCCC'
    },
    actionDate: {
        padding: '4px 0 4px 22px',
        width: '448px',
        '& > *': {
            fontSize: '13px',
            lineHeight: '16px'
        }
    },
}));

const ActionTitle = (props) => {
    const classes = useStyles();
    const { stageName } = props;
    return <Box className={classes.titleContainer}>
        <Typography className={classes.actionName}>
            {stageName}
        </Typography>
        <Box className={classes.actionDate}>
            <Typography >Start Date</Typography>
            <Typography >End Date</Typography>
        </Box>
    </Box>
}

export default ActionTitle;
