import React, { useMemo, useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import AWS from "aws-sdk";

import { cb2Promise, useGetAssumedKeysForBuildAccount, useHandleAwsAuthErr } from '../../common/AwsUtil';
import { useDispatchReducerContext } from '../../store';
import { useStateGettersContext } from '../../store';
import { DISMISS_APP_LOADING, SHOW_APP_LOADING, SHOW_TOAST } from '../../store/dispatchNames';
import ConfirmDialog from '../../components/ui/ConfirmDialog';
import retryWhiteIcon from '../../assets/icons/retry_white.png';

const useStyles = makeStyles((theme) => ({
    dialogRetryIcon: {
        width: '18px',
        height: '18px',
        position: 'relative',
        top: '-1px'
    }
}));

const useRetry = (actionList, owner, projectName, pipelineExecutionId, onRefreshRequest) => {
    const classes = useStyles();

    const getAssumedKeys = useGetAssumedKeysForBuildAccount();
    const handleAwsAuthErr = useHandleAwsAuthErr();
    const storeGetters = useStateGettersContext();
    const dispatch = useDispatchReducerContext();
    const [latestPipelineExecutionId, setLatestPipelineExecutionId] = useState(null);
    const [isShowConfirmRetry, setIsShowConfirmRetry] = useState(false);
    const [action, setAction] = useState(null);

    useEffect(() => {
        const codepipeline = new AWS.CodePipeline(storeGetters.readonlyKeys);
        setLatestPipelineExecutionId(null);
        codepipeline.listPipelineExecutions({
            maxResults: '1',
            pipelineName: projectName
        }, function (err, data) {
            if (err) {
                if (handleAwsAuthErr(err)) {
                    console.error(err);
                }
            } else {
                if (data?.pipelineExecutionSummaries?.[0]?.pipelineExecutionId) {
                    setLatestPipelineExecutionId(data.pipelineExecutionSummaries[0].pipelineExecutionId);
                }
            }
        })
    }, [storeGetters.readonlyKeys, projectName, handleAwsAuthErr])

    const actionListWithRetryStatus = useMemo(() => {
        // only latest version of pipeline execution can be enabled
        const isLatestVersionExecution = latestPipelineExecutionId === pipelineExecutionId;
        // only latest Failed action of pipeline execution can be enabled
        const actionWithRetryEnabled = actionList.find(action => action.status === 'Failed');
        // if next action is running or successed, then disabled
        const nextAction = actionList[actionList.indexOf(actionWithRetryEnabled) - 1];
        let isSameActionSuperseded = false;
        if (nextAction) {
            isSameActionSuperseded = nextAction.actionName === actionWithRetryEnabled.actionName && ['InProgress', 'Succeeded'].includes(nextAction.status);
        }
        return actionList.map(item => {
            return {
                ...item,
                retryEnable: !isSameActionSuperseded && isLatestVersionExecution && item === actionWithRetryEnabled
            }
        })
    }, [actionList, latestPipelineExecutionId, pipelineExecutionId])

    const handleRetryClick = (action) => {
        setAction(action);
        setIsShowConfirmRetry(true);
    }

    const retry = async () => {
        let env = "nonprod";
        if (action.actionName === "APPROVE-IN-PROD" || action.actionName === "APPROVE-IN-DATALAKE-PROD" || action.actionName === "DEPLOY-IN-PROD") {
            env = "prod";
        }
        dispatch({
            type: SHOW_APP_LOADING
        })
        try {
            const params = await getAssumedKeys(owner, env);
            const codepipeline = new AWS.CodePipeline(params);
            await cb2Promise(codepipeline, "retryStageExecution", {
                pipelineExecutionId: pipelineExecutionId,
                pipelineName: projectName,
                retryMode: 'FAILED_ACTIONS',
                stageName: action.stageName
            })
            setIsShowConfirmRetry(false);
            dispatch({
                type: SHOW_TOAST,
                payload: {
                    message: 'Successed',
                    severity: 'success'
                }
            });
            setTimeout(() => {
                onRefreshRequest();
            }, 2000);
        } catch (err) {
            if (!handleAwsAuthErr(err)) {
                dispatch({
                    type: SHOW_TOAST,
                    payload: {
                        message: err.message,
                        severity: 'error'
                    }
                });
            }
        } finally {
            dispatch({
                type: DISMISS_APP_LOADING
            })
        }

    }

    const retryComp = <ConfirmDialog
        open={isShowConfirmRetry}
        onClose={() => setIsShowConfirmRetry(false)}
        title="Are you sure you want to retry the stage?"
        onConfirm={retry}
        confirmText="Retry"
        icon={<img src={retryWhiteIcon} alt='retry' className={classes.dialogRetryIcon} />}
    />

    return {
        handleRetryClick,
        retryComp,
        actionListWithRetryStatus
    }
}

export default useRetry;
