import React, { useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, Typography, makeStyles } from '@material-ui/core';

import arrowIcon from '../../assets/icons/cost_arrow_totop.svg';
import arrowActivedIcon from '../../assets/icons/cost_arrow_tobottom_actived.svg';
import moment from 'moment';
import { useCostData } from '../Costreport';
import CostGraph from '../Costreport/CostGraph';
import CostSummary from '../Costreport/CostSummary';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    title: {
        fontWeight: 500,
        fontSize: '16px',
        color: '#E66400',
        cursor: 'pointer',
        position: 'absolute',
        right: '28px',
        top: '-20px',
        '& *': {
            verticalAlign: 'middle'
        }
    },
    activeTitle: {
        color: '#AC4B00'
    },
    arrow: {
        position: 'relative',
        top: '-1px'
    },
    costContainer: {
        padding: '16px 0'
    },
    chartContainer: {
        height: '250px',
        background: '#ffffff',
    },
    loadingContainer: {
        textAlign: 'center',
        paddingTop: '40px'
    },
    loading: {
        color: '#E66400',
        marginLeft: '6px',
        position: 'relative',
        top: '1px'
    },
    costSummary: {
        position: 'absolute',
        top: '30px',
        right: '16px'
    }
}));

const Cost = (props) => {
    const classes = useStyles();
    const { projectName } = props;

    const [open, setOpen] = useState(false);
    const [loadData, setLoadData] = useState(false);

    const handleClick = (event) => {
        setOpen(!open);
        setLoadData(true);
    };

    useEffect(() => {
        setOpen(false);
    }, [projectName])

    const params = useMemo(() => ({
        dateParam: {
            startDate: moment().subtract(31, 'day').toDate(),
            endDate: moment().subtract(1, 'day').toDate(),
            granularity: 'DAILY'
        },
        dimension: 'Env',
        filters: {
            component: {
                condition: 'Includes',
                values: [projectName]
            }
        }
    }), [projectName]);

    const {
        costData,
        costLoading,
        hadInitCeParams
    } = useCostData(params, loadData);

    return <Box className={classes.container}>
        <Typography onClick={handleClick} className={`${classes.title}${open ? ` ${classes.activeTitle}` : ''}`} variant="h2" >
            <img src={open ? arrowActivedIcon : arrowIcon} className={classes.arrow} alt='arrow' /> Cost Chart
        </Typography>
        {
            open && <Box className={classes.costContainer}>
                <Box className={classes.chartContainer}>
                    {
                        hadInitCeParams ? <>
                            <CostSummary className={classes.costSummary} type="small" generalData={costData} loading={costLoading} granularity={params.dateParam.granularity} />
                            <CostGraph detailedData={costData} loading={costLoading} granularity={params.dateParam.granularity} type="small" />
                        </> : <Box className={classes.loadingContainer}>
                            <CircularProgress className={classes.loading} size={32} />
                        </Box>
                    }
                </Box>
            </Box>
        }

    </Box>;
}

export default Cost;