import React from 'react';

import {
    Box
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import removeFilterIcon from '../../../assets/icons/remove_filter.svg';
import CommonTextField from '../../../components/ui/CommonTextField';
import CommonMenuItem from '../../../components/ui/CommonMenuItem';
import groupingIcon from '../../../assets/icons/grouping.svg';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '6px'
    },
    clear: {
        position: 'absolute',
        fontSize: '14px',
        color: '#E66400',
        cursor: 'pointer',
        right: '10px',
        top: '20px',
        zIndex: 1,
        '& > *': {
            verticalAlign: 'middle'
        }
    },
    title: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#4D4E53',
        '& > *': {
            verticalAlign: 'middle'
        }
    },
    removeIcon: {
        position: 'relative',
        left: '-6px',
        top: '-2px'
    },
    groupingIcon: {
        marginRight: '10px',
        position: 'relative',
        top: '-1px'
    },
    formContainer: {
        position: 'relative',
        padding: '6px 8px'
    },
    textField: {
        '& .MuiFormLabel-root': {
            fontSize: '14px',
            fontWeight: 400
        },
        '& .MuiInputBase-input': {
            paddingLeft: '8px'
        }
    },
}))

const DimensionParam = (props) => {
    const classes = useStyles();
    const { value, onChange } = props;

    const onDimensionChange = (e) => {
        onChange(e.target.value);
    }
    const onClear = () => {
        onChange('None');
    }

    return <Box className={classes.container}>
        <Typography className={classes.title}>
            <img src={groupingIcon} alt='Time' className={classes.groupingIcon} />
            Group by
        </Typography>
        <Box className={classes.formContainer}>
            <Typography className={classes.clear} onClick={onClear}>
                <img src={removeFilterIcon} alt='Time' className={classes.removeIcon} />
                Clear
            </Typography>
            <CommonTextField
                className={classes.textField}
                select
                label="Dimension"
                value={value}
                onChange={onDimensionChange}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                readOnly
                fullWidth
            >
                <CommonMenuItem value="None">None</CommonMenuItem>
                <CommonMenuItem value="Owner">Product Owner</CommonMenuItem>
                <CommonMenuItem value="SERVICE">AWS Service</CommonMenuItem>
                <CommonMenuItem value="Service">Component</CommonMenuItem>
                <CommonMenuItem value="Env">Env</CommonMenuItem>
                <CommonMenuItem value="INSTANCE_TYPE">Instance Type</CommonMenuItem>
            </CommonTextField>
        </Box>
    </Box>
}

export default DimensionParam;
