import React, { Fragment, useMemo, useState } from 'react';
import { Link } from "react-router-dom";

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core';

import { useDispatchReducerContext, useStateReducerContext } from '../../store';
import NoData from '../../components/ui/NoData';
import { REMOVE_BOOKMARK } from '../../store/dispatchNames';

import bookmarkedIcon from '../../assets/icons/bookmarked.png';
import asendingIcon from '../../assets/icons/sort_asending.png';
import decendingIcon from '../../assets/icons/sort_decending.png';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '24px 70px',
    },
    orderContainer: {
        padding: '10px 22px',
        color: '#4C4948',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        background: '#ECECEC',
        fontFamily: 'Roboto',
        borderBottom: '0.5px solid #CCCCCC',
        '& > *': {
            verticalAlign: 'middle'
        }
    },
    letterArea: {
        color: '#4C4948',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        padding: '10px 23px',
        background: '#ECECEC',
        borderBottom: '0.5px solid #CCCCCC'
    },
    projectItem: {
        background: '#FFFFFF',
        display: 'block',
        padding: '7px 20px 7px 10px',
        color: '#4C4948',
        fontFamily: 'Roboto',
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '14px',
        borderBottom: '0.5px solid #CCCCCC',
        "&:hover": {
            background: '#F7EED7'
        }
    },
    bookmarkBtn: {
        padding: '6px',
        marginRight: '20px'
    },
    nodata: {
        marginTop: '60px'
    },
    bookmarkedIcon: {
        width: '24px',
        height: '24px'
    },
    sortIcon: {
        width: '18px',
        height: '18px',
        marginLeft: '6px',
        cursor: 'pointer'
    }
}));

const Bookmark = () => {
    const classes = useStyles();
    const [orderType, setOrderType] = useState('asending'); // 'asending' | 'decending'
    const dispatch = useDispatchReducerContext();
    const storeState = useStateReducerContext();

    const handleBookmarkClick = (event, id) => {
        event.preventDefault();
        dispatch({
            type: REMOVE_BOOKMARK,
            payload: {
                bookmark: id
            }
        });
    }

    const projectListByLetter = useMemo(() => {
        return storeState.bookmarks
            .sort((a, b) => {
                if (orderType === 'asending')
                    return a.toUpperCase() > b.toUpperCase() ? 1 : -1
                return b.toUpperCase() > a.toUpperCase() ? 1 : -1
            })
            .reduce((total, project) => {
                const tagItem = total.find(item => item.tag === project[0].toUpperCase());
                if (tagItem) {
                    tagItem.list.push(project)
                } else {
                    total.push({
                        tag: project[0].toUpperCase(),
                        list: [project]
                    })
                }

                return total;
            }, [])
    }, [storeState.bookmarks, orderType])

    return <Box className={classes.container}>
        <Box className={classes.orderContainer}>
            Pipeline/Repository Name
            <img src={orderType === 'asending' ? asendingIcon : decendingIcon} onClick={() => setOrderType(orderType === 'asending' ? 'decending' : 'asending')} alt='sort icon' className={classes.sortIcon} />
        </Box>
        {
            projectListByLetter.length === 0 && <NoData className={classes.nodata} title="No Bookmark Data" subTitle="Please bookmark the necessary pipelines." />
        }
        {
            projectListByLetter.map(item => {
                return <Fragment key={item.tag}>
                    <Box key={item.tag} className={classes.letterArea}>{item.tag}</Box>
                    {
                        item.list.map(projectItem => <Link to={`/project/${projectItem}`} key={projectItem} className={classes.projectItem}>
                            <IconButton className={classes.bookmarkBtn} onClick={(event) => handleBookmarkClick(event, projectItem)}>
                                <img src={bookmarkedIcon} alt='bookmarked' className={classes.bookmarkedIcon} />
                            </IconButton>
                            {projectItem}
                        </Link>)
                    }
                </Fragment>
            })
        }
    </Box>
}

export default Bookmark;