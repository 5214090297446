import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { styled } from "@material-ui/core/styles";

export const CommonDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
        boxShadow: '6px 1px 30px 0px rgba(0, 0, 0, 0.30)',
        borderRadius: 0,
        borderTop: '6px solid #E66400'
    }
});

export const CommonDialogTitle = styled(DialogTitle)({
    padding: '50px 40px 32px',
    color: '#454545',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '31px'
});

export const CommonDialogContent = styled(DialogContent)({
    padding: '0 40px',
});

export const CommonDialogActions = styled(DialogActions)({
    padding: '40px 53px',
    '&.MuiDialogActions-spacing > :not(:first-child)': {
        marginLeft: '24px'
    }
});