import TextField from "@material-ui/core/TextField";
import visibilityOn from '../../assets/icons/visibility_on.svg'
import visibilityOff from '../../assets/icons/visibility_off.svg'
import InputAdornment from '@material-ui/core/InputAdornment';

import { styled } from "@material-ui/core/styles";
import { useMemo, useState } from "react";

const StyledTextField = styled(TextField)({
    '& .MuiFormLabel-root': {
        color: '#4D4E53',
        fontSize: '14px',
        fontWeight: 600,
        whiteSpace: 'nowrap'
    },
    '& .MuiFormLabel-asterisk': {
        color: '#E83646'
    },
    '& .MuiInput-input': {
        background: '#FFF',
        height: '20px',
        paddingLeft: '14px',
        paddingRight: '8px',
        borderRadius: '4px',
    },
    '& .MuiInput-multiline': {
        paddingTop: '0',
    },
    '& .MuiInput-inputMultiline': {
        paddingTop: '6px',
        paddingBottom: '7px',
    },
    '& .MuiInput-root': {
        marginTop: '22px',
        border: '0.5px solid #C4C4C4',
        borderRadius: '4px',
    },
    '& .MuiInput-underline': {
        '&:before, &:after': {
            display: 'none'
        }
    },
    '& .visibilityIcon': {
        width: '18px',
        height: '18px',
        position: 'relative',
        left: '-10px',
        top: '-1px',
        cursor: 'pointer'
    },
});

const CommonTextField = (props) => {

    const type = props.type;
    const [isShowPassword, setIsShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setIsShowPassword(!isShowPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const newType = useMemo(() => {
        if (type === 'password' && isShowPassword) {
            return 'text';
        }
        return type;
    }, [type, isShowPassword]);

    const isShowVisibilityIcon = useMemo(() => {
        return type === 'password';
    }, [type]);

    return <StyledTextField {...props} type={newType}
        InputProps={{
            ...props.InputProps,
            endAdornment: isShowVisibilityIcon ? (
                <InputAdornment position="end">
                    <img className="visibilityIcon" onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        src={isShowPassword ? visibilityOn : visibilityOff}
                        alt="visibility" />
                </InputAdornment>
            ): <></> ,
        }}
    >
        {props.children}
    </StyledTextField>;
};

export default CommonTextField;