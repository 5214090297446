import React, { useMemo, useState } from 'react';

import {
    Box,
    Popover,
    Typography,
    makeStyles
} from '@material-ui/core';
import CommonTextField from '../../../components/ui/CommonTextField';
import CancelButton from '../../../components/ui/CancelButton';
import timeIcon from '../../../assets/icons/time.svg';
import calendarIcon from '../../../assets/icons/calendar.svg';
import applyIcon from '../../../assets/icons/apply.svg';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import CommonMenuItem from '../../../components/ui/CommonMenuItem';
import SecondaryButton from '../../../components/ui/SecondaryButton';
import { SHOW_TOAST } from '../../../store/dispatchNames';
import { useDispatchReducerContext } from '../../../store';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#4D4E53',
        '& > *': {
            verticalAlign: 'middle'
        }
    },
    timeIcon: {
        marginRight: '10px',
        position: 'relative',
        top: '-1px'
    },
    formContainer: {
        padding: '6px 8px'
    },
    textField: {
        '& .MuiFormLabel-root': {
            fontSize: '14px',
            fontWeight: 400
        },
        '& .MuiInputBase-input': {
            paddingLeft: '8px'
        }
    },
    calendarIcon: {
        marginLeft: '8px',
        position: 'relative',
        top: '-1px'
    },
    calendarButtonsArea: {
        borderTop: '1px solid #CCCCCC',
        padding: '20px',
        textAlign: 'right'
    },
    cancelButton: {
        marginRight: '24px'
    }
}));

const DateParam = (props) => {
    const classes = useStyles();
    const dispatch = useDispatchReducerContext();
    const {value, onChange} = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const [granularity, setGranularity] = useState('MONTHLY');
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const handleClick = (event) => {
        setDateRange([{
            startDate: value.startDate,
            endDate: value.endDate,
            key: 'selection'
        }]);
        setGranularity(value.granularity);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const validate = (granularity, startDate, endDate) => {
        if (granularity === 'DAILY' && moment(endDate).diff(startDate, 'days') > 30) {
            dispatch({
                type: SHOW_TOAST,
                payload: {
                    message: 'You can only select a date range within 30 days. If you want to view data greater than 30 days, please select Granularity as Monthly.',
                    severity: 'error'
                }
            });

            return false;
        }

        return true;
    }

    const handlyGranularityChange = (e) => {
        if (!validate(e.target.value, value.startDate, value.endDate)) return;
        setGranularity(e.target.value);
        onChange({
            startDate: value.startDate,
            endDate: value.endDate,
            granularity: e.target.value,
        });
    }

    const handleApply = () => {
        if (!validate(granularity, dateRange[0].startDate, dateRange[0].endDate)) return;
        onChange({
            startDate: dateRange[0].startDate,
            endDate: dateRange[0].endDate,
            granularity,
        });
        handleClose();
    }

    const dateRangeFormatString = useMemo(() => {
        return `${moment(value.startDate).format('YYYY-MM-DD')} - ${moment(value.endDate).format('YYYY-MM-DD')}`;
    }, [value])

    return <Box>
        <Typography className={classes.title}>
            <img src={timeIcon} alt='Time' className={classes.timeIcon} />
            Time
        </Typography>
        <Box className={classes.formContainer}>
        <CommonTextField
            className={classes.textField}
            label="Date Range"
            value={dateRangeFormatString}
            onClick={handleClick}
            InputLabelProps={{ shrink: true }}
            margin="normal"
            InputProps={{
                startAdornment: <img src={calendarIcon} alt='Calendar' className={classes.calendarIcon} />,
              }}
            readOnly
            fullWidth
        />
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            
            <DateRangePicker
                onChange={item => setDateRange([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={dateRange}
                direction="horizontal"
                preventSnapRefocus={true}
                calendarFocus="backwards"
            />
            <Box className={classes.calendarButtonsArea}>
                <CancelButton className={classes.cancelButton} onClick={handleClose}>Cancel</CancelButton>
                <SecondaryButton startIcon={<img src={applyIcon} alt='apply' />} onClick={handleApply}>Apply</SecondaryButton>

            </Box>
        </Popover>
        <CommonTextField
            select
            className={classes.textField}
            label="Granularity"
            value={granularity}
            onChange={handlyGranularityChange}
            InputLabelProps={{ shrink: true }}
            margin="normal"
            readOnly
            fullWidth
        >
            <CommonMenuItem value="DAILY">Daily</CommonMenuItem>
            <CommonMenuItem value="MONTHLY">Monthly</CommonMenuItem>
        </CommonTextField>
        </Box>
    </Box>
}

export default DateParam;
