import React from 'react';
import Typography from '@material-ui/core/Typography';

function ApplicationHelp() {
    return (
        <>
            <ul>
                <li>
                    <Typography variant="caption" display="block" color="textPrimary" gutterBottom>
                        Two sepaarte repositories are created, one for application code and another for infrastructure code.
                    </Typography>
                </li>
                <li>
                    <Typography variant="caption" display="block" color="textPrimary" gutterBottom>
                        Pipeline creation to deploy the infrastructure and applicaion is in progress.
                    </Typography>
                </li>
                <li>
                    <Typography variant="caption" display="block" color="textPrimary" gutterBottom>
                        Once created, pipelines for infrastructure as well as application will appear on the dashboard. Waiting time is around 10-15 mins. 
                    </Typography>
                </li>
                <li>
                    <Typography variant="caption" display="block" color="textPrimary" gutterBottom>
                        First, deploy the infrasture for your application using infrastructure pipline.
                    </Typography>
                </li>
                <li>
                    <Typography variant="caption" display="block" color="textPrimary" gutterBottom>
                        Clone the infrastrure repository, generate infrastructure project using the archetype ==archetype-name==. 
                    </Typography>
                </li>
                <li>
                    <Typography variant="caption" display="block" color="textPrimary" gutterBottom>
                        Update the topology files for all the enviroments as per your application's need. Check in the project and deploy the infrastrure.
                    </Typography>
                </li>
                <li>
                    <Typography variant="caption" display="block" color="textPrimary" gutterBottom>
                        Clone the application repoDetails, generate the application project using the archetype ==archetype-name==
                    </Typography>
                </li>
                <li>
                    <Typography variant="caption" display="block" color="textPrimary" gutterBottom>
                        Check in and deploy the application in enviroment(s) using the cicd engine.
                    </Typography>
                </li>
            </ul>
        </>
    )
}

export default ApplicationHelp;