import React, { useEffect, useRef, useState } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import Fab from '@material-ui/core/Fab';
import backToTopIcon from '../../assets/icons/backtop_white.png';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'fixed',
        right: '50px',
        bottom: '58px',
        width: '42px',
        height: '42px',
        backgroundColor: '#104A6B',
        '&:hover': {
            backgroundColor: '#09283A'
        }
    },
    icon: {
        width: '25px',
        height: 'auto'
    }
}))

const BackToTop = () => {
    const classes = useStyles();
    const ele = useRef();
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        const parentElement = ele.current && ele.current.parentElement;
        const scrollCB = () => {
            const scrolled = parentElement.scrollTop;
            if (scrolled > 300) {
                setVisible(true)
            } else if (scrolled <= 300) {
                setVisible(false)
            }
        }

        if (parentElement) {
            parentElement.addEventListener('scroll', scrollCB);
        }
        return () => {
            if (parentElement) {
                parentElement.removeEventListener('scroll', scrollCB);
            }
        }
    }, [ele])

    const scrollToTop = () => {
        if (ele.current && ele.current.parentElement) {
            ele.current.parentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    return <Fab className={classes.container} style={{display: visible ? 'block' : 'none'}} ref={ele} onClick={scrollToTop}>
        <img src={backToTopIcon} alt='back to top' className={classes.icon} />
    </Fab>
}

export default BackToTop;