import React, { useState } from 'react';

import { cb2Promise, useGetAssumedKeysForBuildPureRole, useHandleAwsAuthErr } from '../../common/AwsUtil';
import { getConstants } from '../../common/env.constants';
import ApplicationHelp from './helpTxt/ApplicationHelpTxt';
import ClientHelp from './helpTxt/ClientHelpTxt';
import { DISMISS_APP_LOADING, SHOW_APP_LOADING, SHOW_TOAST } from '../../store/dispatchNames';
import { useDispatchReducerContext } from '../../store';
import { CommonDialog, CommonDialogTitle, CommonDialogContent, CommonDialogActions } from '../ui/CommonDialog';
import CommonButton from '../ui/CommonButton';
import CancelButton from '../ui/CancelButton';
import CommonTextField from "../ui/CommonTextField";
import ConfirmDialog from '../ui/ConfirmDialog';
import CommonMenuItem from "../ui/CommonMenuItem";

import AWS from "aws-sdk";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Slide from "@material-ui/core/Slide";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Add from "@material-ui/icons/Add";

const PROJECT_TYPES = [{
    title: 'Application',
    value: 'Application'
}, {
    title: 'UI',
    value: 'UI'
}, {
    title: 'Lib',
    value: 'Lib'
}, {
    title: 'Lambda',
    value: 'lambda'
}, {
    title: 'Load Test Client',
    value: 'loadtest'
}, {
    title: 'Salesforce UI',
    value: 'salesforce-ui'
}, {
    title: 'AWS Resource',
    value: 'aws-resource'
}];

const useCreatedStyles = makeStyles((theme) => ({
    BoxPanel: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        marginBottom: 10,
        background: "rgb(253 253 253)",
        border: "1px solid rgb(146 146 146 / 12%)",
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function CreatedDialog(props) {
    const history = useHistory();
    const classes = useCreatedStyles();

    const handleProjectListClose = () => {
        history.push("/");
        props.handleClose();
    };

    return <CommonDialog
        open={props.openProjectCreated}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"md"}
    >
        <CommonDialogTitle>Repositories Created</CommonDialogTitle>
        <CommonDialogContent>
            <Box className={classes.BoxPanel}>
                {props.projectList.map((project) => (
                    <CommonTextField
                        InputProps={{
                            readOnly: true,
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        key={project.cloneUrlHttp}
                        style={{ paddingBottom: 20 }}
                        label={project.repositoryName}
                        placeholder={project.repositoryName}
                        value={`codecommit::eu-west-1://${project.repositoryName}`}
                    />
                ))}
            </Box>
            <Box>{props.appType}</Box>
            {props.appType === "Application" ? <ApplicationHelp /> : <ClientHelp />}
        </CommonDialogContent>
        <CommonDialogActions>
            <CancelButton onClick={handleProjectListClose} autoFocus>
                Close
            </CancelButton>
        </CommonDialogActions>
    </CommonDialog>
}

const useCreatingStyles = makeStyles((theme) => ({
    creatingContainer: {
        '& .MuiDialog-paper': {
            width: '750px',
            maxWidth: '750px',
        }
    },
    subtitle: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: 1,
        display: 'block'
    },
    textfield: {
        width: '250px',
        marginTop: 0,
    },
    portfield: {
        width: '64px',
        marginTop: 0,
        marginLeft: '8px'
    },
    divider: {
        margin: '0 0 16px',
        backgroundColor: '#4C4948'
    },
    infoalert: {
        backgroundColor: '#F6F6F6',
        color: '#4C4948',
        fontSize: '12px',
        padding: '8px',
        marginBottom: '16px',
        '& .MuiAlert-icon': {
            color: '#4C4948',
            fontSize: '18px',
            paddingTop: 0,
            marginRight: '6px'
        },
        '& .MuiAlert-message': {
            marginBottom: '6px',
            padding: '0 80px 0 0'
        },
        '& .MuiAlert-message p': {
            marginBottom: 0,
            marginTop: 0,
        }
    },
    gridContainer: {
        '& .MuiGrid-item': {
            marginBottom: '32px'
        },
        '& .MuiGrid-item:nth-child(even)': {
            paddingLeft: '45px'
        }
    },
    optionalTitle: {
        color: '#CCC',
        fontWeight: 400
    },
    descTextArea: {
        '& .MuiInput-inputMultiline': {
            resize: 'vertical'
        }
    }
}));

const AppCreateProject = (props) => {
    const classes = useCreatingStyles();
    const handleAwsAuthErr = useHandleAwsAuthErr();

    const getAssumedKeys = useGetAssumedKeysForBuildPureRole();
    const dispatch = useDispatchReducerContext();
    const constValue = getConstants();
    const [isShowConfirmCreate, setIsShowConfirmCreate] = useState(false);
    const [prjectContext, setProjectContext] = useState("ads");
    const [port, setPort] = useState("");
    const [appType, setAppType] = useState("Application");
    const [prjectName, setProjectName] = useState("<Project Name>");
    const [proAccountIsError, setProAccountIsError] = useState(false);
    const [projectAccount, setProjectAccount] = useState("none");
    const [proAccountMessage, setProAccountMessage] = useState('');
    const [prjectDesc, setProjectDesc] = useState("");
    const [runTime, setRunTime] = useState("java");
    const [projectNameErrortext, setProjectNameErrortext] = useState('');
    const [validateFail, setValidateFail] = useState(false);
    const [projectShortName, setProjectShortName] = useState("");
    const [projectList, setProjectLink] = useState([]);
    const [openProjectCreated, setOpenProjectCreated] = useState(false);

    // reset form
    const resetForm = () => {
        setProjectContext("ads");
        setProjectName("<Project Name>");
        setProjectShortName("");
        setPort("");
        setAppType("Application");
        setProjectDesc("");
        setValidateFail(false);
        setProjectNameErrortext("");
    }

    const validate = () => {
        if (appType === 'Application') {
            return (validateFail || prjectName === '<Project Name>' || projectShortName.length === 0 || port.length < 4) ? true : false;
        } else {
            return (validateFail || prjectName === '<Project Name>') ? true : false;
        }
    };

    const makeDynamoDbEntry = async () => {
        const dynamoPutItemParams = {
            Item: {
                repositoryname: {
                    S: prjectName.toLowerCase(),
                },
                reposhortname: {
                    S: appType.toLowerCase() !== "application" ? prjectName.toLowerCase() : projectShortName.toLowerCase(),
                },
                pipetype: {
                    S: appType.toLowerCase(),
                },
                pipecreated: {
                    BOOL: false,
                },
                owner: {
                    S: prjectContext.toLowerCase(),
                },
                port: {
                    S: port.toLowerCase(),
                },
                runtime: {
                    S: appType.toLowerCase() === 'lambda' ? runTime : "",
                },
                datalakeaccount: {
                    BOOL: projectAccount === 'Datalake account' ? true : false,
                },
            },
            TableName: constValue.dynamoDBName,
        };
        const dynamoDBParams = await getAssumedKeys(prjectContext.toLowerCase());
        const dynamoDB = new AWS.DynamoDB(dynamoDBParams);
        const data = await cb2Promise(dynamoDB, 'putItem', dynamoPutItemParams);
        console.log("Dynamo entry success " + JSON.stringify(data));
    };

    const createProject = async (e) => {
        setIsShowConfirmCreate(false);
        if (projectAccount === 'none') {
            setProAccountIsError(true);
            setProAccountMessage('Please select an appropriate account');
            return;
        }

        setProjectLink([]);
        let repoDetails = [];
        let repositoryName = prjectName.toLowerCase();
        let createInfraRepository = false;
        if (appType === "Application") {
            createInfraRepository = true;
        }
        let params = {
            repositoryName: repositoryName,
            repositoryDescription: prjectDesc,
            tags: {
                'Owner': prjectContext.toLowerCase(),
                'Env': 'cicd',
                'Environment': 'cicd',
                'Service': repositoryName,
                'ProjectName': repositoryName,
                'Name': repositoryName,
                'BU': 'udcs'
            }
        };

        try {
            dispatch({
                type: SHOW_APP_LOADING
            })
            const assumedKeys = await getAssumedKeys(prjectContext.toLowerCase());
            const codecommit = new AWS.CodeCommit(assumedKeys);
            const repository = await cb2Promise(codecommit, 'createRepository', params);
            console.log("Repo created:: ", projectShortName);
            repoDetails.push(repository.repositoryMetadata);
            if (createInfraRepository) {
                var infraParams = {
                    repositoryName: repositoryName + "-infrastructure",
                    repositoryDescription:
                        "Infrastructure repository for " + repositoryName,
                    tags: {
                        'owner': prjectContext.toLowerCase(),
                    }
                };
                const infraRepository = await cb2Promise(codecommit, 'createRepository', infraParams);
                repoDetails.push(infraRepository.repositoryMetadata);
                console.log("Now make an entry in dymano db");
                await makeDynamoDbEntry();
                setProjectLink(repoDetails);
                props.handleClose();
                setOpenProjectCreated(true);
            } else {
                console.log("Now make an entry in dymano db");
                await makeDynamoDbEntry();
                setProjectLink(repoDetails);
                props.handleClose();
                setOpenProjectCreated(true);
            }
            dispatch({
                type: DISMISS_APP_LOADING
            })
        } catch (err) {
            dispatch({
                type: DISMISS_APP_LOADING
            })
            if (!handleAwsAuthErr(err)) {
                if (err.code === 'RepositoryNameExistsException') {
                    setValidateFail(true);
                    setProjectNameErrortext(err.message);
                } else {
                    dispatch({
                        type: SHOW_TOAST,
                        payload: {
                            message: err.message,
                            severity: 'error'
                        }
                    });
                }
            }
        }
    };

    const setApplicationType = (type) => {
        console.log(type);
        setAppType(type)
    };

    const projectNameValidateHandle = (e) => {
        if (!e.target.value || e.target.value === '') {
            setProjectShortName('');
            setProjectName('<Project Name>');
            setValidateFail(false);
            setProjectNameErrortext('')
        } else {
            let pattern = /^[A-Za-z][A-Za-z0-9-]*$/;
            let result = pattern.test(e.target.value);
            if (result) {
                setProjectShortName(e.target.value);
                setProjectName(e.target.value);
                setValidateFail(false);
                setProjectNameErrortext('')
            } else {
                setValidateFail(true);
                setProjectNameErrortext('Please enter letters, numbers, or hyphens, and the first character must be a letter')
            }
        }

    }

    const handleProjectAccount = (e) => {
        setProjectAccount(e.target.value);
        if (e.target.value === 'Main account') {
            setProAccountMessage('Pipeline will be created for test, qa and prod accounts');
        } else if (e.target.value === 'Datalake account') {
            setProAccountMessage('Pipeline will be created for datalake qa and datalake prod accounts');
        } else {
            setProAccountMessage('');
        }
        setProAccountIsError(false);
    }

    const onCreatedDialogClose = () => {
        setOpenProjectCreated(false);
        props.handleClose();
        resetForm();
    }

    return <>
        <CommonDialog
            open={props.open}
            onClose={props.handleClose}
            className={classes.creatingContainer}
            aria-labelledby="alert-dialog-title"
            TransitionComponent={Transition}
            disableEscapeKeyDown
        >
            <CommonDialogTitle>
                Create Project
                <Typography className={classes.subtitle} variant="inherit" gutterBottom>
                    Here you can create a new SCM repository.
                </Typography>
                <Typography className={classes.subtitle} variant="inherit" gutterBottom>
                    The repository name will only use lowercase characters:{" "}
                    e.g.: vehicle-repository
                </Typography>
            </CommonDialogTitle>
            <CommonDialogContent>
                <Grid container className={classes.gridContainer} spacing={0}>
                    <Grid item xs={6}>
                        <CommonTextField
                            className={classes.textfield}
                            required
                            select
                            error={proAccountIsError}
                            helperText={proAccountIsError ? proAccountMessage : null}
                            label="Project Account"
                            value={projectAccount}
                            onChange={handleProjectAccount}
                            fullWidth
                        >
                            <CommonMenuItem value="none">
                                <span style={{ color: 'grey' }}>Please select an account</span>
                            </CommonMenuItem>
                            <CommonMenuItem value="Main account">Main account</CommonMenuItem>
                            <CommonMenuItem value="Datalake account">Datalake account</CommonMenuItem>
                        </CommonTextField>
                    </Grid>
                    <Grid item xs={6}>
                        <CommonTextField
                            required
                            select
                            className={classes.textfield}
                            label="Product Owner"
                            value={prjectContext}
                            onChange={(e) => setProjectContext(e.target.value)}
                            fullWidth
                        >
                            <CommonMenuItem value="ads">ADS - Admin & Driver Services</CommonMenuItem>
                            <CommonMenuItem value="ffs">FFS - Fuel & Fleet Services</CommonMenuItem>
                            <CommonMenuItem value="rms">RMS - Repair & Maintenance Services</CommonMenuItem>
                            <CommonMenuItem value="ims">IMS - Infra & Monitoring Services</CommonMenuItem>
                            <CommonMenuItem value="fw">Framework</CommonMenuItem>
                            <CommonMenuItem value="saas">CORS - Core Services</CommonMenuItem>
                        </CommonTextField>
                    </Grid>
                </Grid>

                <Divider className={classes.divider} />
                <Alert severity="info" className={classes.infoalert}>
                    <AlertTitle style={{ fontSize: 15 }}>{PROJECT_TYPES.find(item => item.value === appType)?.title ?? '-'}</AlertTitle>
                    {appType === "Application" ? <p> 2 repositories will be created. 1 for infrastructure with the name <b>"{prjectName}-infrastructure"</b> and another for application with the name <b>"{prjectName}"</b></p>
                        : <p>1 repository with the name <b>"{prjectName}"</b> will be created</p>
                    }
                </Alert>
                <Grid container className={classes.gridContainer} spacing={0}>
                    <Grid item xs={6}>
                        <CommonTextField
                            className={classes.textfield}
                            InputLabelProps={{ shrink: true }}
                            required
                            select
                            label="Project Type"
                            value={appType}
                            onChange={(e) => setApplicationType(e.target.value)}
                            fullWidth
                        >
                            {PROJECT_TYPES.map(item => <CommonMenuItem value={item.value} key={item.value}>{item.title}</CommonMenuItem>)}
                        </CommonTextField>
                    </Grid>
                    {appType === "lambda" && <Grid item xs={6} >
                        <CommonTextField
                            className={classes.textfield}
                            required
                            select
                            label="Runtime"
                            value={runTime}
                            onChange={(e) => setRunTime(e.target.value)}
                            fullWidth
                        >
                            <CommonMenuItem value="java">Java</CommonMenuItem>
                            <CommonMenuItem value="nodejs">Node</CommonMenuItem>
                            <CommonMenuItem value="python">Python</CommonMenuItem>
                        </CommonTextField>
                    </Grid>}
                    <Grid item xs={6}>
                        <CommonTextField
                            className={classes.textfield}
                            required
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            error={validateFail}
                            onChange={(e) => projectNameValidateHandle(e)}
                            label="Project Name"
                            helperText={projectNameErrortext}
                            placeholder="E.g. vehicle-respository"
                            fullWidth
                        />
                    </Grid>
                    {appType === "Application" && (
                        <Grid item xs={6}>
                            <CommonTextField
                                className={classes.textfield}
                                InputLabelProps={{ shrink: true }}
                                required
                                margin="normal"
                                error={validateFail}
                                value={appType !== 'Application' ? prjectName : projectShortName}
                                onChange={(e) => setProjectShortName(e.target.value)}
                                label="Project Short Name"
                                placeholder="E.g. vhgw"
                                InputProps={{
                                    readOnly: appType !== 'Application',
                                }}
                                disabled={appType !== 'Application' ? true : false}
                                fullWidth
                            />
                            {appType !== "lambda" && <CommonTextField
                                required
                                margin="normal"
                                className={classes.portfield}
                                InputLabelProps={{ shrink: true }}
                                value={appType !== 'Application' ? "NA" : port}
                                onChange={(e) => setPort(e.target.value)}
                                label="Port Number"
                                placeholder="E.g. 12345"
                                type="number"
                                maxLength={5}
                                InputProps={{
                                    readOnly: appType !== 'Application',
                                }}
                                disabled={appType !== 'Application' ? true : false}
                                fullWidth
                            />}
                        </Grid>
                    )}
                    <Grid item xs={6} >
                        <CommonTextField
                            className={`${classes.textfield} ${classes.descTextArea}`}
                            label={<>
                                Description
                                <span className={classes.optionalTitle}> - Optional</span>
                            </>}
                            margin="normal"
                            multiline
                            minRows={2}
                            value={prjectDesc}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setProjectDesc(e.target.value)}
                            placeholder="E.g. Description"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </CommonDialogContent>
            <CommonDialogActions>
                <CancelButton onClick={props.handleClose}>Cancel</CancelButton>
                <CommonButton onClick={() => setIsShowConfirmCreate(true)} color="primary" disabled={validate()} startIcon={<Add />} autoFocus>
                    Create
                </CommonButton>
            </CommonDialogActions>
        </CommonDialog>
        <CreatedDialog
            openProjectCreated={openProjectCreated}
            handleClose={onCreatedDialogClose}
            projectList={projectList}
            appType={appType}
        />
        <ConfirmDialog
            open={isShowConfirmCreate}
            onClose={() => setIsShowConfirmCreate(false)}
            title="Are you sure you want to create project?"
            onConfirm={createProject}
            confirmText="Create"
            icon={<Add />}
        />
    </>
}

export default AppCreateProject;
