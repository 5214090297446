import React, { useEffect, useState } from 'react';

import { useStateReducerContext } from '../../store';

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { styled } from "@material-ui/core/styles";

import errorIcon from '../../assets/icons/error_white.png';
import successIcon from '../../assets/icons/success_white.png';
import warningIcon from '../../assets/icons/warning_white.png';

const NAlert = styled(Alert)({
    borderTopLeftRadius: '24px',
    borderBottomLeftRadius: '24px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '&.MuiAlert-standardError': {
        backgroundColor: '#E83646',
    },
    '&.MuiAlert-standardSuccess': {
        backgroundColor: '#11A38B',
    },
    '&.MuiAlert-standardWarning': {
        backgroundColor: '#D8B200',
    },
    '& .MuiAlert-message': {
        color: '#FFF'
    },
    '& .MuiAlert-icon': {
        marginRight: '16px'
    },
    '& .MuiAlert-icon img': {
        width: '22px',
        height: '22px'
    },
    '& .MuiAlert-action': {
        color: '#FFF'
    }
})

const AppToast = () => {
    const store = useStateReducerContext();

    const [open, setOpen] = useState(false);
    
    useEffect(() => {
        if (store.appToast.key && store.appToast.message) {
            setOpen(true);
        }
    }, [store.appToast])

    const handleClose = () => {
        setOpen(false);
      };

    return <Snackbar
        key={store.appToast.key}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
    >
        <NAlert
            onClose={handleClose}
            severity={store.appToast.severity}
            iconMapping={{
                success: <img src={successIcon} alt='success' />,
                warning: <img src={warningIcon} alt='warning' />,
                error: <img src={errorIcon} alt='error' />
            }}
        >{store.appToast.message}</NAlert>
    </Snackbar>;
}

export default AppToast;