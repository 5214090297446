import React from 'react';

import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import notFoundImg from '../../assets/404.png'

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    img: {
        width: '450px',
        marginBottom: '6px'
    },
    title: {
        color: '#C4C4C4',
        fontSize: '36px',
        fontWeight: 500,
        lineHeight: '50px',
    },
    subTitle: {
        color: '#C4C4C4',
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: 1,
        marginBottom: '12px'
    },
    link: {
        color: '#E66400',
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: 2.5,
        fontFamily: 'Roboto',
        '&:hover': {
            color: '#AC4B00',
            textDecorationLine: 'underline'
        }
    }
}));

const NotFound = () => {
    const classes = useStyles();

    return <Box className={classes.container}>
        <img src={notFoundImg} alt='404' className={classes.img} />
        <Typography variant="h4" className={classes.title}>Page Not Found</Typography>
        <Typography className={classes.subTitle}>Sorry but the page you are looking for does not exist.</Typography>
        <Link className={classes.link} to="/">&lt; Bring me to home page</Link>
    </Box>
}

export default NotFound;
