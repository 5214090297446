import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import AWS from "aws-sdk";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';

import AppUserPopover from './AppUserPopover';
import { useHandleAwsAuthErr } from '../../common/AwsUtil';
import { getConstants } from '../../common/env.constants';
import CommonButton from "../ui/CommonButton";
import addIcon from '../../assets/icons/create_white.svg';
import avatarIcon from '../../assets/icons/avatar.svg';
import searchIcon from '../../assets/icons/search.svg';
import { useStateGettersContext } from '../../store';
import AppCreateProject from "./AppCreateProject";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFFFFF',
    backgroundImage: 'none',
    boxShadow: 'none',
    borderBottom: '1px solid #CCCCCC'
  },
  toolbar: {
    height: '72px',
    paddingLeft: '30px',
    paddingRight: 0
  },
  title: {
    flexGrow: 1,
    color: "#565352",
    display: "none",
    fontSize: '22px',
    fontWeight: 800,
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    width: '560px !important',
    borderRadius: '20px',
    marginRight: '30px',
    border: '1px solid #4C4948',
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
    '& .MuiInputBase-input': {
      height: '22px'
    },
    '& .MuiAutocomplete-endAdornment': {
      right: '8px'
    }
  },
  searchIcon: {
    padding: '0 14px',
    height: "16px",
    width: "16px",
    position: "absolute",
    pointerEvents: "none",
    top: '9px'
  },
  autoSearch: {
    '& .MuiAutocomplete-input': {
      paddingLeft: '38px !important'
    }
  },
  createBtn: {
    marginRight: '30px'
  },
  avatar: {
    cursor: 'pointer',
    paddingRight: 30,
    paddingLeft: 30,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      width: '48px',
      height: '48px',
    },
    '&:hover,&.active': {
      backgroundColor: '#F7EED7'
    }
  },
  btnIcon: {
    width: '18px',
    height: '18px',
  }
}));

const AppNavBar = function () {
  const classes = useStyles();
  const history = useHistory();
  const storeGetters = useStateGettersContext();
  const handleAwsAuthErr = useHandleAwsAuthErr();

  const constValue = getConstants();

  const [projectDataList, setProjectDataList] = useState([]);
  const [avatarPopoverOpen, setAvatarPopoverOpen] = useState(false);
  const avatarEleRef = useRef(null);
  const [openAutoComplete, setOpenAutoComplete] = useState(false);
  const [creatingOpen, setCreatingOpen] = useState(false);
  const loading = openAutoComplete && projectDataList.length === 0;

  useEffect(() => {
    if (!loading) {
      return;
    }

    const codepipeline = new AWS.CodePipeline(storeGetters.readonlyKeys);
    const params = {};
    codepipeline.listPipelines(params, function (err, data) {
      if (err) {
        console.log('err', JSON.stringify(err));
        handleAwsAuthErr(err);
      } else {
        setProjectDataList(data.pipelines);
      }
    });

  }, [loading, storeGetters.readonlyKeys, handleAwsAuthErr]);

  const onClose = (event, reason) => {
    setOpenAutoComplete(false);
    const projectName = event?.target?.innerText;
    if (reason === 'select-option' && projectName && projectName !== '') {
      history.push("/project/" + projectName);
    }

  }

  return <>
    <AppBar position="relative" className={classes.container}>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title} variant="h6" noWrap>
          <Link to="/" style={{ color: "#565352" }}>
            CICD Portal {constValue.environment && `- ${constValue.environment}`}
          </Link>
        </Typography>
        <div className={classes.search}>
          <img src={searchIcon} alt='search icon' className={classes.searchIcon} />
          <Autocomplete
            id="combo-box-demo"
            freeSolo
            options={projectDataList}
            getOptionLabel={(option) => option.name}
            style={{ minWidth: 100 }}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            disableopenonfocus="true"
            open={openAutoComplete}
            onOpen={() => {
              setOpenAutoComplete(true);
            }}
            onClose={onClose}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.autoSearch}
                placeholder="Search Pipeline"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </div>
        <CommonButton onClick={() => setCreatingOpen(true)} className={classes.createBtn} startIcon={<img className={classes.btnIcon} src={addIcon} alt='create icon' />}>
          Create Project
        </CommonButton>
        <Divider orientation="vertical" flexItem />
        <div className={`${classes.avatar}${avatarPopoverOpen ? ' active' : ''}`} onClick={() => setAvatarPopoverOpen(true)} >
          <img src={avatarIcon} alt="avatar" ref={avatarEleRef} />
        </div>
        <AppUserPopover anchorEl={avatarEleRef.current} open={avatarPopoverOpen} onClose={() => setAvatarPopoverOpen(false)} />
      </Toolbar>
    </AppBar>
    <AppCreateProject open={creatingOpen} handleClose={() => setCreatingOpen(false)} />
  </>;
}

export default AppNavBar;