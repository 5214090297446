import React, { useEffect, useMemo, useState } from 'react';

import AWS from "aws-sdk";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import stopPic from '../../assets/icons/stop.svg';
import stopWhitePic from '../../assets/icons/stop_white.svg';
import triggerPic from '../../assets/icons/trigger.svg';
import arrowRightIcon from '../../assets/icons/arrow_toright.svg';
import infoIcon from '../../assets/icons/info.svg';
import infoHoverIcon from '../../assets/icons/info_hover.svg';
import buildWhiteIcon from '../../assets/icons/build_white.svg';


import CommonTooltip from '../../components/ui/CommonTooltip';
import ConfirmDialog from '../../components/ui/ConfirmDialog';
import { useDispatchReducerContext, useStateGettersContext } from '../../store';
import { cb2Promise, useGetAssumedKeysForBuildPureRole, useHandleAwsAuthErr } from '../../common/AwsUtil';
import { DISMISS_APP_LOADING, SHOW_APP_LOADING, SHOW_TOAST } from '../../store/dispatchNames';

const useStyles = makeStyles((theme) => ({
    executionhistorytitle: {
        color: '#4C4948',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        marginTop: '16px',
        background: '#ECECEC',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        borderBottom: '0.5px solid #CCCCCC'
    },
    executionhistorycontent: {
        color: '#4D4E53',
        fontSize: '15px',
        fontWeight: 400,
        lineHeight: 1,
        background: '#FFFFFF',
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '0.5px solid #C4C4C4'
    },
    'detail-icon-btn': {
        position: 'absolute',
        right: '20px',
        top: '8px',
        padding: '6px',
        '&:hover': {
            backgroundColor: '#F7EED7'
        },
        '& img': {
            width: '24px',
            height: '24px',
        }
    },
    'more-icon-btn-wrapper': {
        position: 'absolute',
        right: '100px',
        top: '8px',
    },
    'more-icon-btn': {
        color: '#4C4948',
        padding: '6px',
        '&:hover, &.active': {
            backgroundColor: '#F7EED7'
        }
    },
    'infoicon': {
        width: '18px',
        height: '18px',
        color: '#E66400',
        cursor: 'pointer',
        marginLeft: '14px',
        '&:hover': {
            content: `url(${infoHoverIcon})`
        }
    },
    'status-info': {
        background: '#FFF',
        boxShadow: '0px 2px 7px 0px rgba(0, 0, 0, 0.16)',
        borderRadius: 0,
        color: '#4C4948',
        fontSize: '13px',
        fontWeight: 400,
        padding: '16px 0',
        maxWidth: 'none',
    },
    'status-info-item': {
        margin: '0 22px',
        display: 'inline-block',
        verticalAlign: 'middle',
        '& > *': {
            verticalAlign: 'middle',
            display: 'inline-block'
        }
    },
    'dot-Succeeded': {
        width: '10px',
        height: '10px',
        background: '#11A38B',
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '12px',
        position: 'relative',
        top: '-1px'
    },
    'dot-InProgress': {
        width: '10px',
        height: '10px',
        background: '#D8B200',
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '12px',
        position: 'relative',
        top: '-1px'
    },
    'dot-Failed': {
        width: '10px',
        height: '10px',
        background: '#E83646',
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '12px',
        position: 'relative',
        top: '-1px'
    },
    'dot-Cancelled': {
        width: '10px',
        height: '10px',
        background: '#4C4948',
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '12px',
        position: 'relative',
        top: '-1px'
    },
    'dot-Stopped': {
        width: '10px',
        height: '10px',
        border: '3px solid #4C4948',
        boxSizing: 'border-box',
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '12px',
        position: 'relative',
        top: '-1px'
    },
    'dot-Superseded': {
        width: '10px',
        height: '10px',
        background: 'linear-gradient(#4C4948 50%,#FFF 50%);',
        backgroundSize: '100% 2.86px',
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '12px',
        position: 'relative',
        top: '-1px'
    },
    'executionhistory-version-content': {
        position: 'relative',
        paddingLeft: '28px',
        height: '50px',
        width: '12.8%',
        display: 'flex',
        alignItems: 'center'
    },
    'executionhistory-comment-content': {
        paddingLeft: '28px',
        height: '50px',
        fontSize: '13px',
        fontFamily: 'Roboto',
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row'
    },
    'executionhistory-comment-text': {
        flex: 1,
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 3,
        overflow: 'hidden'
    },
    'executionhistory-comment-env': {
        padding: '0 16px'
    },
    'executionhistory-comment-envitem': {
        display: 'inline-block',
        border: '1.5px solid currentColor',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
        borderRadius: '11px',
        textAlign: 'center',
        fontSize: '13px',
        fontWeight: 600,
        color: '#4C4948',
        padding: '4px 19px',
        background: '#FFFFFF',
        '& + &': {
            marginLeft: '6px'
        }

    },
    'executionhistory-startdate-content': {
        position: 'relative',
        paddingLeft: '28px',
        fontSize: '13px',
        width: '12.8%',
        display: 'flex',
        alignItems: 'center'
    },
    'executionhistory-duration-content': {
        position: 'relative',
        paddingLeft: '28px',
        fontSize: '13px',
        width: '20%',
        display: 'flex',
        alignItems: 'center'
    },
    'executionhistory-version': {
        paddingLeft: '28px',
        width: '12.8%',
        fontSize: '13px',
        height: '40px',
        borderRight: '0.5px solid #CCCCCC',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    'executionhistory-comment': {
        paddingLeft: '28px',
        flex: 1,
        height: '40px',
        lineHeight: 1,
        fontSize: '13px',
        display: 'flex',
        alignItems: 'center',
        borderRight: '0.5px solid #CCCCCC'
    },
    'executionhistory-startdate': {
        paddingLeft: '28px',
        height: '40px',
        width: '12.8%',
        fontSize: '13px',
        display: 'flex',
        alignItems: 'center',
        borderRight: '0.5px solid #CCCCCC'
    },
    'executionhistory-duration': {
        paddingLeft: '28px',
        height: '40px',
        width: '20%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        '& > *': {
            fontSize: '13px',
            lineHeight: '14px'
        }
    },
    'area-loading': {
        color: '#E66400',
        width: '16px !important',
        height: '16px !important',
    },
    'version-container': {
        borderRadius: '11px 4px 4px 11px',
        whiteSpace: 'nowrap',
        border: '1.5px solid currentColor',
        background: '#FFF',
        boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.10)',
        display: 'inline-block',
        padding: '4px 8px',

        '& > *': {
            verticalAlign: 'middle',
            display: 'inline-block',
        }
    },
    'version-text': {
        lineHeight: '14px',
        fontSize: '13px',
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '88px',
    },
    loadingContainer: {
        textAlign: 'center',
        paddingTop: '40px'
    },
    loading: {
        color: '#E66400',
    },
    envLoading: {
        color: '#E66400',
        marginLeft: '6px',
        position: 'relative',
        top: '1px'
    },
    'executionhistory-action-item': {
        padding: '20px 26px',
        color: '#4C4948',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '14px',
        cursor: 'pointer',
        '& + &': {
            borderTop: '0.5px solid #E1E1E1',
        },
        '& > *': {
            verticalAlign: 'middle',
        },
        '&:hover': {
            background: '#F7EED7'
        }
    },
    'executionhistory-action-icon': {
        width: '18px',
        height: '18px',
        marginRight: '8px',
        position: 'relative',
        top: '-1px'
    },
    'stop-confirm-icon': {
        width: '18px',
        height: '18px',
        position: 'relative',
        top: '-2px'
    },
    'build-confirm-icon': {
        width: '18px',
        height: '18px',
        position: 'relative',
        top: '-1px'
    }
}));

const Version = ({ row }) => {
    const classes = useStyles();

    const colorMap = {
        Succeeded: '#11A38B',
        InProgress: '#D8B200',
        Failed: '#E83646',
        Stoped: '#4C4948',
        Stopping: '#4C4948',
        Cancelled: '#4C4948',
        Superseded: '#4C4948'
    };

    let tooltipText = row.status;
    if (!row.version?.version?.N) {
        tooltipText = row.pipelineExecutionId;
    }


    return <Box className={classes['executionhistory-version-content']} style={{ color: colorMap[row.status] }}>
        <CommonTooltip
            interactive
            title={tooltipText}
        >
            <div className={classes[`version-container`]}>
                <div className={classes[`dot-${row.status === 'Stopping' ? 'Stopped' : row.status}`]}></div>
                <Typography className={classes[`version-text`]}>{row.version && row.version.version && row.version.version.N ? row.version.version.N : row.pipelineExecutionId}</Typography>
            </div>
        </CommonTooltip>
    </Box>
}

const renderDuration = (row) => {
    const last = moment(row.lastUpdateTime);
    const duration = last.diff(moment(row.startTime));
    if (duration === 0) return '-';
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / 1000 / 60) % 60);
    let hours = Math.floor((duration / 1000 / 3600) % 24);
    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
    return hours + ":" + minutes + ":" + seconds;
};

const ExecutionHistory = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const getAssumedKeys = useGetAssumedKeysForBuildPureRole();
    const storeGetters = useStateGettersContext();
    const dispatch = useDispatchReducerContext();
    const handleAwsAuthErr = useHandleAwsAuthErr();
    const [pipelineList, setPipelineList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);
    const [loadTrigger, setLoadTrigger] = useState(true);
    const [moreAnchorEl, setMoreAnchorEl] = useState(null);
    const [isShowConfirmStop, setIsShowConfirmStop] = useState(false);
    const [isShowConfirmBuild, setIsShowConfirmBuild] = useState(false);
    const moreOpen = Boolean(moreAnchorEl);
    const projectName = props.projectName;
    const owner = props.owner;
    const envExecutionIdMap = props.envExecutionIdMap;


    const compPipelineList = useMemo(() => {
        return pipelineList.map(pipeline => {
            const envs = envExecutionIdMap ?
                envExecutionIdMap.filter(env => env.pipelineExecutionId === pipeline.pipelineExecutionId) :
                [];

            return {
                ...pipeline,
                envs
            }
        })
    }, [pipelineList, envExecutionIdMap]);


    useEffect(() => {
        const codepipeline = new AWS.CodePipeline(storeGetters.readonlyKeys);
        const dynamoDB = new AWS.DynamoDB(storeGetters.readonlyKeys);
        let didMount = true;

        setLoading(true);
        Promise.all([
            cb2Promise(codepipeline, 'listPipelineExecutions', {
                pipelineName: projectName
            }),
            cb2Promise(dynamoDB, 'query', {
                ExpressionAttributeValues: {
                    ':repository': { S: projectName }
                },
                KeyConditionExpression: 'repositoryname = :repository',
                ProjectionExpression: 'repositoryname, version, pipelineexecutionid',
                TableName: 'pipeline-version-manager'
            }),
        ]).then(([pipelineData, dbData]) => {
            if (!didMount) return;
            const pipelineExecutionData = pipelineData.pipelineExecutionSummaries;
            const versionMappingData = dbData.Items;

            const mergeData = [];
            if (pipelineExecutionData && pipelineExecutionData.length > 0 && versionMappingData) {
                pipelineExecutionData.forEach((obj, index) => {
                    mergeData.push({
                        lastUpdateTime: obj.lastUpdateTime,
                        pipelineExecutionId: obj.pipelineExecutionId,
                        sourceRevisions: obj.sourceRevisions,
                        startTime: obj.startTime,
                        status: obj.status,
                        trigger: obj.trigger,
                        version: versionMappingData.find(e => e.pipelineexecutionid.S === obj.pipelineExecutionId),
                        rowIndex: index
                    });
                });
            }
            setPipelineList(mergeData);
        }).catch((err) => {
            if (!handleAwsAuthErr(err)) {
                console.error(err);
            }
        }).finally(() => {
            if (!didMount) return;
            setFirstLoading(false);
            setLoading(false);
        })

        return () => didMount = false;
    }, [projectName, handleAwsAuthErr, storeGetters.readonlyKeys, loadTrigger]);

    useEffect(() => {
        setFirstLoading(true);
        setPipelineList([]);
    }, [projectName])

    useEffect(() => {
        const interval = setInterval(() => {
            setLoadTrigger((flag) => !flag);
        }, 15000);

        return () => {
            clearInterval(interval);
        }
    }, [])

    const [currentActionRow, setCurrentActionRow] = useState(null);

    const handleMoreClick = (event, row) => {
        if (row && (row.rowIndex === 0 || row.status === 'InProgress')) {
            setMoreAnchorEl(event.currentTarget);
            setCurrentActionRow(row);
        }
    };

    const handleMoreClose = () => {
        setMoreAnchorEl(null);
    };

    const stopExcution = async () => {
        handleMoreClose();
        dispatch({
            type: SHOW_APP_LOADING
        })
        try {
            const params = await getAssumedKeys(owner);
            const codepipeline = new AWS.CodePipeline(params);
            await cb2Promise(codepipeline, 'stopPipelineExecution', {
                pipelineExecutionId: currentActionRow.pipelineExecutionId,
                pipelineName: projectName,
                abandon: true,
                reason: "User wants to stop this",
            });
            setLoadTrigger((flag) => !flag);
            dispatch({
                type: SHOW_TOAST,
                payload: {
                    message: 'Pipeline execution is stopped',
                    severity: 'success'
                }
            });
        } catch (err) {
            if (!handleAwsAuthErr(err)) {
                dispatch({
                    type: SHOW_TOAST,
                    payload: {
                        message: err.message,
                        severity: 'error'
                    }
                });
            }
        } finally {
            dispatch({
                type: DISMISS_APP_LOADING
            })
            setIsShowConfirmStop(false)
        }
    }

    const buildExcution = async () => {
        handleMoreClose();
        dispatch({
            type: SHOW_APP_LOADING
        })
        try {
            const params = await getAssumedKeys(owner);
            const codepipeline = new AWS.CodePipeline(params);
            await cb2Promise(codepipeline, 'startPipelineExecution', {
                name: projectName
            });
            setLoadTrigger((flag) => !flag);
            dispatch({
                type: SHOW_TOAST,
                payload: {
                    message: 'Fresh build started successfully',
                    severity: 'success'
                }
            });
        } catch (err) {
            if (!handleAwsAuthErr(err)) {
                dispatch({
                    type: SHOW_TOAST,
                    payload: {
                        message: err.message,
                        severity: 'error'
                    }
                });
            }
        } finally {
            dispatch({
                type: DISMISS_APP_LOADING
            })
            setIsShowConfirmBuild(false)
        }
    }

    return <>
        <Box className={classes.executionhistorytitle}>
            <Typography className={classes['executionhistory-version']}>
                Version
                <Tooltip classes={{ tooltip: classes['status-info'] }} interactive
                    title={<>
                        <div className={classes['status-info-item']}>
                            <div className={classes['dot-Succeeded']}></div>
                            <Typography>Success</Typography>
                        </div>
                        <div className={classes['status-info-item']}>
                            <div className={classes['dot-InProgress']}></div>
                            <Typography>InProgress</Typography>
                        </div>
                        <div className={classes['status-info-item']}>
                            <div className={classes['dot-Failed']}></div>
                            <Typography>Failed</Typography>
                        </div>
                        <div className={classes['status-info-item']}>
                            <div className={classes['dot-Cancelled']}></div>
                            <Typography>Cancelled</Typography>
                        </div>
                        <div className={classes['status-info-item']}>
                            <div className={classes['dot-Stopped']}></div>
                            <Typography>Stopped/Stopping</Typography>
                        </div>
                        <div className={classes['status-info-item']}>
                            <div className={classes['dot-Superseded']}></div>
                            <Typography>Superseded</Typography>
                        </div>
                    </>}
                >
                    <img src={infoIcon} className={classes['infoicon']} alt="info" />
                </Tooltip>
            </Typography>
            <Typography className={classes['executionhistory-comment']}>
                Comment
            </Typography>
            <Typography className={classes['executionhistory-startdate']}>
                Start Date
            </Typography>
            <Box className={classes['executionhistory-duration']}>
                <Typography >Duration</Typography>
                <Typography >(HH:mm:ss)</Typography>
            </Box>
        </Box>
        <Popover
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={moreOpen}
            anchorEl={moreAnchorEl}
            onClose={handleMoreClose}
        >
            {currentActionRow && currentActionRow.rowIndex === 0 && <Typography className={classes['executionhistory-action-item']} onClick={() => setIsShowConfirmBuild(true)}><img className={classes['executionhistory-action-icon']} src={triggerPic} alt='trigger' />Trigger Build</Typography>}

            {currentActionRow && currentActionRow.status === 'InProgress' && <Typography className={classes['executionhistory-action-item']} onClick={() => setIsShowConfirmStop(true)}><img className={classes['executionhistory-action-icon']} src={stopPic} alt='trigger' />Stop Execution</Typography>}
        </Popover>
        <ConfirmDialog
            open={isShowConfirmStop}
            onClose={() => setIsShowConfirmStop(false)}
            title="Are you sure you want to stop pipeline excution?"
            onConfirm={stopExcution}
            confirmText="Stop"
            icon={<img className={classes['stop-confirm-icon']} src={stopWhitePic} alt='trigger' />}
        />
        <ConfirmDialog
            open={isShowConfirmBuild}
            onClose={() => setIsShowConfirmBuild(false)}
            title="Are you sure you want to trigger new build?"
            onConfirm={buildExcution}
            confirmText="Build"
            icon={<img src={buildWhiteIcon} alt='build' className={classes['build-confirm-icon']} />}
        />
        {
            loading && firstLoading && <Box className={classes.loadingContainer}><CircularProgress className={classes.loading} /></Box>
        }
        {
            compPipelineList.map(row =>
                <Box className={classes.executionhistorycontent} key={row.pipelineExecutionId}>
                    <Version row={row} />
                    <div className={classes['executionhistory-comment-content']}>
                        <span className={classes['executionhistory-comment-text']}>
                            <CommonTooltip title={row.sourceRevisions && row.sourceRevisions.length > 0 ? row.sourceRevisions[0].revisionSummary : '-'} interactive>
                                <span>
                                    {row.sourceRevisions && row.sourceRevisions.length > 0 ? row.sourceRevisions[0].revisionSummary : '-'}
                                </span>
                            </CommonTooltip>
                        </span>

                        <div className={classes['executionhistory-comment-env']}>{
                            row.envs.map(item => {
                                return <span key={item.env} className={classes['executionhistory-comment-envitem']}>
                                    {item.env.split('-')[2]}
                                    {item.status === 'InProgress' && <CircularProgress className={classes.envLoading} size={10} />}
                                </span>
                            })
                        }</div>
                    </div>
                    <Typography className={classes['executionhistory-startdate-content']}>
                        {moment(row.startTime).format('YYYY-MM-DD HH:mm:ss')}
                    </Typography>
                    <Typography className={classes['executionhistory-duration-content']}>
                        {renderDuration(row)}

                        <CommonTooltip title='Actions'>
                            <span className={classes['more-icon-btn-wrapper']}>
                                <IconButton
                                    className={
                                        `${classes['more-icon-btn']}${(currentActionRow && moreAnchorEl && currentActionRow.pipelineExecutionId === row.pipelineExecutionId) ? ' active' : ''}`
                                    }
                                    disabled={row.rowIndex !== 0 && row.status !== 'InProgress'}
                                    onClick={(event) => handleMoreClick(event, row)}>
                                    <MoreVertIcon />
                                </IconButton>
                            </span>
                        </CommonTooltip>
                        <CommonTooltip title='Pipeline Detail'>
                            <IconButton className={classes['detail-icon-btn']} onClick={() => history.push(`/execution/${projectName}/${row.pipelineExecutionId}`)}>
                                <img src={arrowRightIcon} alt='detail icon' />
                            </IconButton>
                        </CommonTooltip>
                    </Typography>
                </Box>
            )
        }

    </>
}

export default ExecutionHistory;
