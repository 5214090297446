import React from 'react';
import {
  Redirect,
  useLocation
} from "react-router-dom";
import { useStateGettersContext } from '../store';

export function HomeGurad() {
    const stateGetters = useStateGettersContext();
    const location = useLocation();

    const { isLogin } = stateGetters;

    return isLogin ? 
        <></> : 
        <Redirect to={{
            pathname: '/login',
            search: `?referer=${location.pathname}`}}
        />;
}

export function LoginGurad(props) {
    const stateGetters = useStateGettersContext();
    const { isLogin } = stateGetters;

    return isLogin ? 
        <Redirect to={{
            pathname: props.referer ?? '/'
        }}
        /> : <></>;
}