import React, { useEffect, useState } from 'react';

import AWS from "aws-sdk";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import RefreshIcon from '../../assets/icons/refresh.svg';

import { cb2Promise, useGetAssumedKeysForDeployAccounts, useHandleAwsAuthErr } from '../../common/AwsUtil';
import CommonTooltip from '../../components/ui/CommonTooltip';
import { useDispatchReducerContext } from '../../store';
import { SHOW_TOAST } from '../../store/dispatchNames';
import RefreshInstanceDialog from './RefreshInstanceDialog';

const useStyles = makeStyles((theme) => ({
    title: {
        color: '#454545',
        fontSize: '22px',
        fontWeight: 700,
        paddingLeft: '8px'
    },
    infraContainer: {
        marginTop: '28px'
    },
    tabContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: '8px 0'
    },
    tab: {
        color: '#4C4948',
        fontSize: '14px',
        fontWeight: 400,
        borderBottom: '0.5px solid #4C4948',
        padding: '7px 15px',
        display: 'block',
        position: 'relative',
        cursor: 'pointer',
        '& > *': {
            verticalAlign: 'middle'
        },
        '&::before': {
            content: '""',
            display: 'block',
            width: '0.5px',
            height: '30px',
            background: '#4C4948',
            position: 'absolute',
            left: 0,
            bottom: '4px'
        },
        '&:last-of-type::after': {
            content: '""',
            display: 'block',
            width: '0.5px',
            height: '30px',
            background: '#4C4948',
            position: 'absolute',
            right: 0,
            bottom: '4px'
        }
    },
    restArea: {
        flex: 1,
        borderBottom: '0.5px solid #4C4948',
        position: 'relative'
    },
    activeTab: {
        background: '#F7EED7',
        border: '0.5px solid #4C4948',
        borderBottom: 'none',
        cursor: 'auto',
        fontWeight: 600,
        '&::after': {
            width: '0 !important'
        },
        '&::before': {
            width: '0 !important'
        },
        '& + p::before': {
            width: '0 !important'
        }
    },
    iconBtnContainer: {
        padding: '6px !important',
        textAlign: 'center !important',
        borderLeft: '0.5px solid #CCCCCC'
    },
    iconBtn: {
        padding: '6px',
        '&:hover': {
            backgroundColor: '#F7EED7'
        }
    },
    infraTable: {
        width: '100%',
        color: '#4D4E53',
        fontFamily: 'Roboto',
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: 1,
        borderCollapse: 'collapse',
        '& th': {
            textAlign: 'left',
            padding: '10px 0 10px 22px',
            borderRight: '0.5px solid #CCC',
            fontWeight: 400,
            background: '#ECECEC',
            lineHeight: '20px'
        },
        '& td': {
            textAlign: 'left',
            padding: '18px 0 18px 22px',
            background: '#FFFFFF',
        },
        '& tbody tr': {
            borderBottom: '1px solid #C4C4C4',
        },
        '& th:last-child': {
            borderRight: 'none'
        }
    },
    loading: {
        color: '#E66400',
        width: '16px !important',
        height: '16px !important',
        position: 'absolute',
        top: "8px",
        left: '16px'
    },
    refreshIcon: {
        width: '24px',
        height: '24px',
    }
}));

const InfraArea = (props) => {
    const classes = useStyles();
    const { projectShortName, pipelineOwner } = props;

    const handleAwsAuthErr = useHandleAwsAuthErr();
    const getAssumedKeysForDeployAccounts = useGetAssumedKeysForDeployAccounts();
    const dispatch = useDispatchReducerContext();
    const [env, setEnv] = useState('test');
    const [loadTrigger, setLoadTrigger] = useState(true);
    const [isRefreshDialogOpen, setIsRefreshDialogOpen] = useState(false);
    const [isShowInfra, setIsShowInfra] = useState(false);
    const [instanceDetails, setInstanceDetails] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setLoadTrigger((flag) => !flag);
        }, 15000);

        return () => {
            clearInterval(interval);
        }
    }, [])

    useEffect(() => {
        const fetchAutoscallingGroup = async () => {
            if (!projectShortName || !pipelineOwner || pipelineOwner === '' || projectShortName === '') {
                setIsShowInfra(false);
                return;
            }
            
            try {
                const assumedKeys = await getAssumedKeysForDeployAccounts(pipelineOwner, env, 'r');
                const autoscaling = new AWS.AutoScaling(assumedKeys);
                const ec2 = new AWS.EC2(assumedKeys);
                setLoading(true);
                const autoScalingGroupsData = await cb2Promise(autoscaling, 'describeAutoScalingGroups', {
                    AutoScalingGroupNames: [
                        projectShortName + "-asg-" + env
                    ]
                });

                if (autoScalingGroupsData.AutoScalingGroups.length > 0) {
                    const listOfInstanceIds = autoScalingGroupsData.AutoScalingGroups[0].Instances.map((instance) => {
                        return instance.InstanceId
                    });
                    const describeInstancesData = await cb2Promise(ec2, 'describeInstances', {
                        InstanceIds: listOfInstanceIds
                    });
                    let instances = [];
                    if (describeInstancesData.Reservations.length > 0) {

                        describeInstancesData.Reservations.forEach((reservation) => {
                            reservation.Instances.forEach((instance) => {
                                instances.push(instance)
                            })
                        })

                        setInstanceDetails(instances.reverse());
                    }
                    setIsShowInfra(true);
                } else {
                    setIsShowInfra(false);
                }
            } catch (err) {
                setIsShowInfra(false);
                if (!handleAwsAuthErr(err)) {
                    dispatch({
                        type: SHOW_TOAST,
                        payload: {
                            message: err.message,
                            severity: 'error'
                        }
                    });
                }
            } finally {
                setLoading(false);
            }

        }

        fetchAutoscallingGroup();
    }, [dispatch, env, getAssumedKeysForDeployAccounts, handleAwsAuthErr, pipelineOwner, projectShortName, loadTrigger]);

    const handleTabClick = (tabEnv) => {
        if (env === tabEnv) return;
        setEnv(tabEnv)
    }

    return isShowInfra ? <Box className={classes.infraContainer} >
        <Typography className={classes.title} variant="h2" >Infra Area Detail</Typography>
        <Box className={classes.tabContainer}>
            <Typography onClick={() => handleTabClick('test')} className={`${classes.tab}${env === 'test' ? ` ${classes.activeTab}` : ''}`}>
                TEST
            </Typography>
            <Typography onClick={() => handleTabClick('qa')} className={`${classes.tab}${env === 'qa' ? ` ${classes.activeTab}` : ''}`}>
                QA
            </Typography>
            <Typography onClick={() => handleTabClick('prod')} className={`${classes.tab}${env === 'prod' ? ` ${classes.activeTab}` : ''}`}>
                PROD
            </Typography>
            <div className={classes.restArea}>
                {loading && <CircularProgress className={classes.loading} />}
            </div>
        </Box>
        <table className={classes.infraTable}>
            <thead>
                <tr>
                    <th>Instance Type</th>
                    <th>IP</th>
                    <th>Status</th>
                    <th>Refresh Instance</th>
                </tr>
            </thead>
            <tbody>
                {
                    instanceDetails.map((instance, index) => (
                        <tr key={instance.PrivateIpAddress}>
                            <td>{instance.InstanceType}</td>
                            <td>{instance.PrivateIpAddress}</td>
                            <td>{instance.State.Name}</td>
                            {
                                index === 0 && <td rowSpan={instanceDetails.length} className={classes.iconBtnContainer}>
                                    <CommonTooltip title='To refresh infra latest version please click Refresh button'>
                                        <span>
                                            <IconButton className={classes.iconBtn} disabled={loading} onClick={() => setIsRefreshDialogOpen(true)}>
                                                <img src={RefreshIcon} alt='refresh' className={classes.refreshIcon} />
                                            </IconButton>
                                        </span>
                                    </CommonTooltip>
                                </td>
                            }
                        </tr>
                    ))
                }

            </tbody>
        </table>
        <RefreshInstanceDialog
            isOpen={isRefreshDialogOpen}
            onClose={() => setIsRefreshDialogOpen(false)}
            onRefreshSuccess={() => setLoadTrigger((flag) => !flag)}
            projectShortName={projectShortName}
            pipelineOwner={pipelineOwner}
            env={env} />

    </Box > : <></>
}

export default InfraArea;