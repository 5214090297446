export const SET_ACCESS_KEYS = 'SET_ACCESS_KEYS';
export const SET_MFA_KEYS = 'SET_MFA_KEYS';
export const SET_READONLY_KEYS = 'SET_READONLY_KEYS';
export const SET_USERNAME = 'SET_USERNAME';
export const CLEAR_ACCESS_DATA = 'CLEAR_ACCESS_DATA';
export const SHOW_TOAST = 'SHOW_TOAST';
export const CLEAR_ACCESSINFO = 'CLEAR_ACCESSINFO';
export const ADD_BOOKMARK = 'ADD_BOOKMARK';
export const REMOVE_BOOKMARK = 'REMOVE_BOOKMARK';
export const SHOW_APP_LOADING = 'SHOW_APP_LOADING';
export const DISMISS_APP_LOADING = 'DISMISS_APP_LOADING';
export const SET_ASSUMEROLE_PARAM_CACHE = 'SET_ASSUMEROLE_PARAM_CACHE';