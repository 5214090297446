import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import { ContextProvider } from './store';
import Login from './container/Login';
import Home from './container/Home';
import './App.css';
import AppToast from './components/app/AppToast';
import AppLoading from './components/app/AppLoading';
import { HomeGurad } from './components/NavigationGurads';
import NotFound from './container/NotFound';


function App() {
  return (
    <ContextProvider>
      <Router>
        <Switch>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/404" exact>
            <NotFound />
          </Route>
          <Route path="/" >
            <HomeGurad />
            <Home />
          </Route>
          <Route path="*">
            <Redirect to='/404' />
          </Route>
        </Switch>
      </Router>
      <AppToast />
      <AppLoading />
    </ContextProvider>
  );
}

export default App;
