import React from 'react';

import {
    Box, Divider, Typography, makeStyles
} from '@material-ui/core';
import DateParam from './DateParam';
import DimensionParam from './DimensionParam';
import FilterParams from './FilterParams';

const useStyles = makeStyles((theme) => ({
    container: {
        background: '#ffffff',
        height: '100%'
    },
    header: {
        background: '#ececec'
    },
    title: {
        lineHeight: '70px',
        paddingLeft: '20px',
        fontSize: '16px',
        fontWeight: 600,
        color: '#4D4E53'
    },
    content: {
        padding: '18px 22px'
    },
    divider: {
        margin: '2px 0 28px'
    }
}));

const ParamsArea = (props) => {
    const classes = useStyles();
    const {value, onChange} = props;
    const setDateParam = (param) => {
        onChange({
            ...value,
            dateParam: param
        })
    }
    const setDimension = (param) => {
        onChange({
            ...value,
            dimension: param
        })
    }
    const setFilters = (param) => {
        onChange({
            ...value,
            filters: param
        })
    }
    return <Box className={classes.container}>
        <Box className={classes.header}>
            <Typography className={classes.title}>Report Parameters</Typography>
        </Box>
        <Box className={classes.content}>
            <DateParam value={value.dateParam} onChange={setDateParam}/>
            <DimensionParam value={value.dimension} onChange={setDimension} />
            <Divider className={classes.divider} />
            <FilterParams account={value.accountParam} value={value.filters} onChange={setFilters}/>
        </Box>
    </Box>
}

export default ParamsArea;