import React, { useMemo, useState } from 'react';

import { useDispatchReducerContext, useStateReducerContext } from '../../store';
import { SET_ACCESS_KEYS, SET_MFA_KEYS, SET_READONLY_KEYS, SET_USERNAME } from '../../store/dispatchNames';

import loginPic from '../../assets/login_pic.png';
import cautionIcon from '../../assets/icons/caution.png';
import doubleCheckIcon from '../../assets/icons/doublecheck_white.png';
import loginIcon from '../../assets/icons/login_white.png';
import CommonButton from '../../components/ui/CommonButton';
import CancelButton from '../../components/ui/CancelButton';
import CommonTextField from "../../components/ui/CommonTextField";

import AWS from "aws-sdk";
import { useLocation } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from '@material-ui/core/Typography';
import { LoginGurad } from '../../components/NavigationGurads';
import { ACCOUNT_MAP, CICD_ACCOUNT_READONLY_ROLE, cb2Promise } from '../../common/AwsUtil';

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
    },
    contentContainer: {
        height: '100%',
    },
    infoContainer: {
        textAlign: 'center',
    },
    infoWrapper: {
        width: '542px',
        margin: '0 64px 0 auto',
        '@media (min-width: 1900px)': {
            marginRight: '93.5px'
        }
    },
    loginPic: {
        width: '341px',
        marginTop: '40px',
        marginBottom: '52px'
    },
    infoTitle: {
        fontWeight: 800,
        color: '#4D4E53',
        fontSize: '40px'
    },
    infoContent: {
        fontSize: '20px',
        lineHeight: '28px',
        paddingTop: '22px'
    },
    infoWelcome: {
        color: '#4D4E53',
        fontSize: '30px',
        fontWeight: 700,
        lineHeight: 1
    },
    formContainer: {
        textAlign: 'center',
    },
    formWrapper: {
        width: '500px',
        margin: '0 auto 0 64px',
        '@media (min-width: 1900px)': {
            marginLeft: '93.5px'
        }
    },
    formCard: {
        background: '#FFF',
        boxSizing: 'border-box',
        margin: '0 auto',
        boxShadow: '6px 1px 30px -6px rgba(0, 0, 0, 0.30)',
        borderTop: '6px solid #E66400',
        padding: '50px 20px 16px'
    },
    formDiv: {
        padding: '0 104px'
    },
    formTitle: {
        fontWeight: 700,
        color: '#454545'
    },
    formSubTitle: {
        color: '#454545'
    },
    validateBtn: {
        marginTop: '56px'
    },
    dotsArea: {
        marginTop: '34px'
    },
    dot: {
        display: 'inline-block',
        borderRadius: '10px',
        border: '0.5px solid #4C4948',
        width: '10px',
        height: '10px',
        '&.active': {
            background: '#4C4948',
        },
        '& + &': {
            marginLeft: '16px'
        }
    },
    loginBtn: {
        marginLeft: '24px'
    },
    mfaBtnContainer: {
        marginTop: '100px'
    },
    textfield: {
        '& .MuiFormLabel-root': {
            color: '#4D4E53',
            fontSize: '14px',
            fontWeight: 600
        },
        '& .MuiInput-input': {
            borderRadius: '4px',
            border: '0.5px solid #C4C4C4',
            background: '#FFF',
            height: '20px',
            paddingLeft: '14px'
        },
        '& .MuiInput-root': {
            marginTop: '22px'
        },
        '& .MuiInput-underline': {
            '&:before, &:after': {
                display: 'none'
            }
        },
    },
    errorArea: {
        background: '#FFF3F4',
        padding: '14px 16px',
        color: '#E83646',
        display: 'flex',
        flexDirection: 'row',
        marginTop: '16px'
    },
    errorIcon: {
        width: "18px",
        height: "18px",
        marginRight: '8px'
    },
    errorText: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '17px',
        flex: 1,
        textAlign: 'left'
    },
    errorPlaceholder: {
        height: '48px'
    },
    btnIcon: {
        width: '18px',
        height: '18px'
    }
}));

const Login = () => {
    const classes = useStyles();
    const location = useLocation();

    const [errorMsg, setErrorMsg] = useState(null);

    const referer = useMemo(() => {
        if (!location.search) return null;
        return new URLSearchParams(location.search).get('referer');
    }, [location.search]);

    const dispatch = useDispatchReducerContext();
    const storeState = useStateReducerContext();

    const [enableMFA, setEnableMFA] = useState(false);
    const [mfaData, setMfaData] = useState();
    const [mfaCode, setMfaCode] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [accessKey, setAccessKey] = useState(storeState.accessDetails.accessKey ?? '');
    const [secretAccessKey, setSecretAccessKey] = useState(storeState.accessDetails.secretKey ?? '');

    const [accessKeyValidMsg, setAccessKeyValidMsg] = useState('');
    const [secretAccessKeyValidMsg, setSecretAccessKeyValidMsg] = useState('');
    const [mfaValidMsg, setMfaValidMsg] = useState('');

    const handleBackFromMFA = () => {
        setMfaValidMsg('');
        setEnableMFA(false);
    }

    const login = async () => {
        // validation
        let isValid = true;
        if (accessKey.trim() === '') {
            setAccessKeyValidMsg('Please input access key.');
            isValid = false;
        } else {
            setAccessKeyValidMsg('');
        }
        if (secretAccessKey.trim() === '') {
            setSecretAccessKeyValidMsg('Please input secret access key.');
            isValid = false;
        } else {
            setSecretAccessKeyValidMsg('');
        }
        if (!isValid) return false;

        // LOGIN
        setIsLoading(true);
        dispatch({
            type: SET_ACCESS_KEYS,
            payload: {
                accessKey,
                secretKey: secretAccessKey
            }
        })
        const options = {
            apiVersion: "2011-06-15",
            accessKeyId: accessKey,
            secretAccessKey: secretAccessKey,
        };
        let userName;
        let sts = new AWS.STS(options);
        const iam = new AWS.IAM(options);
        const callerIdentityParam = {};

        try {
            setErrorMsg(null);
            const callIndentityData = await cb2Promise(sts, 'getCallerIdentity', callerIdentityParam);
            userName = callIndentityData.Arn.split('user/')[1];
            dispatch({
                type: SET_USERNAME,
                payload: {
                    username: userName
                }
            });

            const pagetMFADeviceParams = {
                UserName: userName
            };
            const mfaListData = await cb2Promise(iam, 'listMFADevices', pagetMFADeviceParams);
            setMfaData(mfaListData);
            setEnableMFA(true);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setErrorMsg(err.message);
        }
    };

    const validateMFA = async (mfadata) => {
        // validation
        let isValid = true;
        if (mfaCode.trim() === '') {
            setMfaValidMsg('Please input MFA code');
            isValid = false;
        } else {
            setMfaValidMsg('');
        }
        if (!isValid) return false;

        try {
            setErrorMsg(null);
            setIsLoading(true);
            const options = {
                apiVersion: "2011-06-15",
                accessKeyId: accessKey,
                secretAccessKey: secretAccessKey,
            };
            let sts = new AWS.STS(options);
            const getSesseionTokenParams = {
                SerialNumber: mfadata.MFADevices[0].SerialNumber,
                TokenCode: mfaCode
            }
            const sessionTokenData = await cb2Promise(sts, 'getSessionToken', getSesseionTokenParams);
            const newTempOptions = {
                apiVersion: "2011-06-15",
                accessKeyId: sessionTokenData.Credentials.AccessKeyId,
                secretAccessKey: sessionTokenData.Credentials.SecretAccessKey,
                sessionToken: sessionTokenData.Credentials.SessionToken
            };
            dispatch({
                type: SET_MFA_KEYS,
                payload: {
                    mfaAccessKeyId: sessionTokenData.Credentials.AccessKeyId,
                    mfaSecretAccessKey: sessionTokenData.Credentials.SecretAccessKey,
                    mfaSessionId: sessionTokenData.Credentials.SessionToken
                }
            })
            sts = new AWS.STS(newTempOptions);
            var params = {
                RoleArn: `arn:aws:iam::${ACCOUNT_MAP.BUILD.id}:role/${CICD_ACCOUNT_READONLY_ROLE}`,
                RoleSessionName: "build-developer-role"
            };
            const assumeRoleData = await cb2Promise(sts, 'assumeRole', params);
            dispatch({
                type: SET_READONLY_KEYS,
                payload: {
                    readOnlyAccessId: assumeRoleData.Credentials.AccessKeyId,
                    readOnlyAccessKey: assumeRoleData.Credentials.SecretAccessKey,
                    readOnlySessionId: assumeRoleData.Credentials.SessionToken
                }
            });
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            if (err.code === 'AccessDenied') {
                setMfaValidMsg('Invalid MFA Code');
            } else {
                setErrorMsg(err.message);
            }
        }
    }

    return <>
        <LoginGurad referer={referer} />
        <Box className={classes.container} >
            <Grid
                container
                justifycontent="center"
                alignItems="center"
                className={classes.contentContainer}
            >
                <Grid item className={classes.infoContainer} xs={6}>
                    <Box className={classes.infoWrapper}>
                        <Typography className={classes.infoTitle} variant="h2" >CICD Portal</Typography>
                        <img src={loginPic} alt='login pic' className={classes.loginPic} />
                        <Typography className={classes.infoWelcome} variant="h5" >Welcome!</Typography>
                        <Typography className={classes.infoContent} variant="subtitle1" >CI/CD is a method to frequently deliver apps to developers by introducing automation into the stages of app development. Let’s get a new experience with your team!</Typography>
                    </Box>

                </Grid>
                <Grid item className={classes.formContainer} xs={6}>
                    <Box className={classes.formWrapper}>
                        <Box className={classes.formCard}>
                            <Typography className={classes.formTitle} variant="h6" >Login</Typography>
                            <Typography className={classes.formSubTitle} variant="subtitle1" >Please enter your {enableMFA ? 'MFA code' : 'credentials'} below</Typography>
                            {!enableMFA && (
                                <>
                                    <div className={classes.formDiv}>
                                        <div>
                                            <CommonTextField
                                                required
                                                error={accessKeyValidMsg !== ''}
                                                helperText={accessKeyValidMsg}
                                                InputLabelProps={{ shrink: true }}
                                                autoComplete='new-password'
                                                fullWidth
                                                margin="normal"
                                                value={accessKey}
                                                onChange={(e) => setAccessKey(e.target.value)}
                                                label="Access Key"
                                                type="password"
                                            />
                                        </div>
                                        <div>
                                            <CommonTextField
                                                required
                                                error={secretAccessKeyValidMsg !== ''}
                                                helperText={secretAccessKeyValidMsg}
                                                InputLabelProps={{ shrink: true }}
                                                autoComplete='new-password'
                                                fullWidth
                                                margin="normal"
                                                value={secretAccessKey}
                                                onChange={(e) => setSecretAccessKey(e.target.value)}
                                                label="Secret Key"
                                                type="password"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <CommonButton className={classes.validateBtn} startIcon={<img className={classes.btnIcon} src={doubleCheckIcon} alt='validate icon' />} onClick={login} loading={isLoading}>
                                            Validate User
                                        </CommonButton>
                                    </div>
                                    {
                                        errorMsg ?
                                            <div className={classes.errorArea}>
                                                <img src={cautionIcon} className={classes.errorIcon} alt='caution' />
                                                <Typography className={classes.errorText}>
                                                    {errorMsg}
                                                </Typography>
                                            </div>
                                            :
                                            <div className={classes.errorPlaceholder}></div>
                                    }

                                </>
                            )}
                            {enableMFA &&
                                (<>
                                    <div className={classes.formDiv}>
                                        <div>
                                            <CommonTextField
                                                required
                                                autoFocus
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                margin="normal"
                                                value={mfaCode}
                                                onChange={(e) => setMfaCode(e.target.value)}
                                                error={mfaValidMsg !== ''}
                                                helperText={mfaValidMsg}
                                                label="MFA Code"
                                                onKeyPress={e => e.key === 'Enter' && validateMFA(mfaData)}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.mfaBtnContainer} >
                                        <CancelButton
                                            onClick={handleBackFromMFA}
                                            loading={isLoading}
                                        >
                                            Cancel
                                        </CancelButton>
                                        <CommonButton loading={isLoading} className={classes.loginBtn} startIcon={<img className={classes.btnIcon} src={loginIcon} alt='login icon' />} onClick={() => validateMFA(mfaData)} color="primary">
                                            Login
                                        </CommonButton>
                                    </div>
                                    {
                                        errorMsg ?
                                            <div className={classes.errorArea}>
                                                <img src={cautionIcon} className={classes.errorIcon} alt='caution' />
                                                <Typography className={classes.errorText}>
                                                    {errorMsg}
                                                </Typography>
                                            </div>
                                            :
                                            <div className={classes.errorPlaceholder}></div>
                                    }
                                </>)
                            }
                        </Box>
                        <Box className={classes.dotsArea}>
                            <div className={`${classes.dot}${enableMFA ? '' : ' active'}`}></div>
                            <div className={`${classes.dot}${enableMFA ? ' active' : ''}`}></div>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </>;
}

export default Login;
