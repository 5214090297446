import React, { createContext, useContext, useMemo, useReducer } from 'react'
import { 
    SET_ACCESS_KEYS, 
    SET_MFA_KEYS, 
    SET_READONLY_KEYS, 
    SET_USERNAME, 
    SHOW_TOAST, 
    CLEAR_ACCESSINFO, 
    ADD_BOOKMARK, 
    REMOVE_BOOKMARK,
    SHOW_APP_LOADING,
    DISMISS_APP_LOADING,
    SET_ASSUMEROLE_PARAM_CACHE
} from './dispatchNames';

const initValue = {
    appToast: {
        key: '',
        message: '',
        severity: '',
    },
    appLoading: false,
    username: localStorage.getItem("n_username") || null,
    accessDetails: {
        accessKey: localStorage.getItem("n_accessKey") || null,
        secretKey: localStorage.getItem("n_secretKey") || null,
    },
    mfaKeys: {
        mfaAccessKeyId: localStorage.getItem("n_mfaAccessKeyId") || null,
        mfaSecretAccessKey: localStorage.getItem("n_mfaSecretAccessKey") || null,
        mfaSessionId: localStorage.getItem("n_mfaSessionId") || null
    },
    assumeRoleParamCache: {},
    readOnlyKeys: {
        readOnlyAccessId: localStorage.getItem("n_readOnlyAccessId") || null,
        readOnlyAccessKey: localStorage.getItem("n_readOnlyAccessKey") || null,
        readOnlySessionId: localStorage.getItem("n_readOnlySessionId") || null
    },
    bookmarks: localStorage.getItem("n_bookmarks") ? JSON.parse(localStorage.getItem("n_bookmarks")) : [],
};

const StateContext = createContext();
const GettersContext = createContext();
const DispatchContext = createContext();

function useContextGetters(state) {
    const isLogin = useMemo(() => state.readOnlyKeys.readOnlyAccessId && state.readOnlyKeys.readOnlyAccessKey && state.readOnlyKeys.readOnlySessionId, [state.readOnlyKeys.readOnlyAccessId, state.readOnlyKeys.readOnlyAccessKey, state.readOnlyKeys.readOnlySessionId]);
    const readonlyKeys = useMemo(() => ({
        accessKeyId: state.readOnlyKeys.readOnlyAccessId,
        secretAccessKey: state.readOnlyKeys.readOnlyAccessKey,
        sessionToken: state.readOnlyKeys.readOnlySessionId,
        region: "eu-west-1"
    }), [state.readOnlyKeys]);

    const getters = useMemo(() => ({
        isLogin,
        readonlyKeys
    }), [isLogin, readonlyKeys])

    return getters;
}

export const ContextProvider = (props) => {
    const [state, dispatch] = useReducer((preState, action) => {
        const { type, payload } = action;

        switch (type) {
            case SHOW_APP_LOADING:
                return {
                    ...preState,
                    appLoading: true
                };
            case DISMISS_APP_LOADING:
                return {
                    ...preState,
                    appLoading: false
                };
            case SHOW_TOAST:
                if (
                    payload.message === preState.appToast.message &&
                    payload.severity === preState.appToast.severity &&
                    new Date().getTime() - preState.appToast.key < 6000
                ) {
                    return preState;
                }
                return {
                    ...preState,
                    appToast: {
                        key: new Date().getTime(),
                        message: payload.message,
                        severity: payload.severity
                    }
                };
            case SET_ACCESS_KEYS:
                localStorage.setItem("n_accessKey", payload.accessKey);
                localStorage.setItem("n_secretKey", payload.secretKey);
                return {
                    ...preState,
                    accessDetails: {
                        accessKey: payload.accessKey,
                        secretKey: payload.secretKey
                    }
                };
            case SET_USERNAME:
                localStorage.setItem("n_username", payload.username);
                return {
                    ...preState,
                    username: payload.username
                };
            case SET_MFA_KEYS:
                localStorage.setItem("n_mfaAccessKeyId", payload.mfaAccessKeyId);
                localStorage.setItem("n_mfaSecretAccessKey", payload.mfaSecretAccessKey);
                localStorage.setItem("n_mfaSessionId", payload.mfaSessionId);
                return {
                    ...preState,
                    mfaKeys: {
                        mfaAccessKeyId: payload.mfaAccessKeyId,
                        mfaSecretAccessKey: payload.mfaSecretAccessKey,
                        mfaSessionId: payload.mfaSessionId
                    }
                };
            case SET_READONLY_KEYS:
                localStorage.setItem("n_readOnlyAccessId", payload.readOnlyAccessId);
                localStorage.setItem("n_readOnlyAccessKey", payload.readOnlyAccessKey);
                localStorage.setItem("n_readOnlySessionId", payload.readOnlySessionId);
                return {
                    ...preState,
                    readOnlyKeys: {
                        readOnlyAccessId: payload.readOnlyAccessId,
                        readOnlyAccessKey: payload.readOnlyAccessKey,
                        readOnlySessionId: payload.readOnlySessionId
                    }
                };
            case SET_ASSUMEROLE_PARAM_CACHE:
                const assumeRoleParamCache = preState.assumeRoleParamCache;
                if (!assumeRoleParamCache[payload.accountId]) {
                    assumeRoleParamCache[payload.accountId] = {}
                }
                assumeRoleParamCache[payload.accountId] = {
                    [payload.role]: payload.param
                }
                return {
                    ...preState,
                    assumeRoleParamCache
                };
            case CLEAR_ACCESSINFO:
                localStorage.removeItem("n_readOnlyAccessId");
                localStorage.removeItem("n_readOnlyAccessKey");
                localStorage.removeItem("n_readOnlySessionId");
                localStorage.removeItem("n_mfaAccessKeyId");
                localStorage.removeItem("n_mfaSecretAccessKey");
                localStorage.removeItem("n_mfaSessionId");
                return {
                    ...preState,
                    readOnlyKeys: {
                        readOnlyAccessId: null,
                        readOnlyAccessKey: null,
                        readOnlySessionId: null
                    },
                    assumeRoleParamCache: {},
                    mfaKeys: {
                        mfaAccessKeyId: null,
                        mfaSecretAccessKey: null,
                        mfaSessionId: null
                    }
                };
            case ADD_BOOKMARK: {
                let bookmarks = preState.bookmarks;
                if (bookmarks.includes(payload.bookmark)) {
                    return preState;
                }
                bookmarks = [...bookmarks, payload.bookmark];
                localStorage.setItem("n_bookmarks", JSON.stringify(bookmarks));
                return {
                    ...preState,
                    bookmarks
                };
            }
            case REMOVE_BOOKMARK: {
                let bookmarks = preState.bookmarks;
                if (!bookmarks.includes(payload.bookmark)) {
                    return preState;
                }
                bookmarks = bookmarks.filter((item) => item !== payload.bookmark)
                localStorage.setItem("n_bookmarks", JSON.stringify(bookmarks));
                return {
                    ...preState,
                    bookmarks
                };
            }
            default:
                return preState;
        }
    }, initValue);

    const getters = useContextGetters(state);

    return (
        <StateContext.Provider value={state} >
            <GettersContext.Provider value={getters} >
                <DispatchContext.Provider value={dispatch} >
                    {props.children}
                </DispatchContext.Provider>
            </GettersContext.Provider>
        </StateContext.Provider>
    );
};

export const useStateReducerContext = () => {
    return useContext(StateContext);
};

export const useStateGettersContext = () => {
    return useContext(GettersContext);
};

export const useDispatchReducerContext = () => {
    return useContext(DispatchContext);
};
