import React from 'react';
import Typography from '@material-ui/core/Typography';

function ClientHelp() {
    return (
        <>
            <ul>
                <li>
                    <Typography variant="caption" display="block" gutterBottom>
                        The repository is created, and the pipeline creation for this project is in progress.
                    </Typography>
                </li>
                <li>
                    <Typography variant="caption" display="block" gutterBottom>
                        Once created, the pipeline will appear on the dashboard. Waiting time is around 10-15 mins.
                    </Typography>
                </li>
                <li>
                    <Typography variant="caption" display="block" gutterBottom>
                        Once the pipeline is created, check out the repository and check in clinet/ui code.
                    </Typography>
                </li>              
            </ul>
        </>
    )
}

export default ClientHelp;