import React from 'react';

import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px 30px'
    },
    title: {
        color: '#454545',
        fontSize: '22px',
        fontWeight: 700,
        lineHeight: 'normal'
    },
    helpContainer: {
        padding: '38px 72px'
    },
    breadcrumb: {
        color: '#4C4948',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
    },
    breadcrumbLink: {
        color: '#AC4B00',
        textDecorationLine: 'underline',
        cursor: 'pointer'
    },
}));

const NewHelp = () => {
    const classes = useStyles();
    const history = useHistory();

    function handleGoBack() {
        const hasPreviousPage = window.history.length > 1;

        if (hasPreviousPage) {
            window.history.back();
        } else {
            // fallback to a meaningful route.
            history.push("/");
        }
    }

    return <Box className={classes.container}>
        <Typography className={classes.breadcrumb}>
            <span className={classes.breadcrumbLink} onClick={handleGoBack}>Last Page</span>&nbsp;&nbsp;&gt;&nbsp;&nbsp;Help Center
        </Typography>
        <Box className={classes.helpContainer}>
            <Typography variant="h4" className={classes.title}>Help Center</Typography>
        </Box>
    </Box>
}

export default NewHelp;
