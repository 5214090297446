
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { styled } from "@material-ui/core/styles";

export default styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ tooltip: className }} />
))(() => ({
    background: '#4C4948',
    boxShadow: '0px 2px 7px 0px rgba(0, 0, 0, 0.16)',
    borderRadius: 0,
    padding: '10px',
    fontSize: '12px',
    margin: 0
}));
