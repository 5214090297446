import { Box, CircularProgress, Divider, Typography, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import Decimal from 'decimal.js';
import averageIconPic from '../../assets/icons/average_cost.svg';
import totalIconPic from '../../assets/icons/total_cost.svg';

const useStyles = makeStyles((theme) => ({
    loading: {
        color: '#E66400',
        marginLeft: '6px',
        position: 'relative',
        top: '1px'
    },
    divider: {
        background: '#C4C4C4',
    },
    container: {
        background: '#FFF',
        display: 'flex',
        padding: '8px 0',
        marginTop: '26px'
    },
    itemContainer: {
        flex: 1,
        textAlign: 'center',
    },
    item: {
        display: 'inline-block'
    },
    itemTitle: {
        textAlign: 'left',
        color: '#4D4E53',
        fontWeight: 400,
        fontSize: '14px',
        padding: '12px 0 8px',
        letterSpacing: '-0.04px',
        '& > *': {
            verticalAlign: 'middle',
        },
        '& img': {
            width: '24px',
            height: '24px',
            position: 'relative',
            marginRight: '20px',
            top: '-1px'
        }
    },
    itemMoney: {
        padding: '16px 0 14px',
        fontSize: '30px',
        lineHeight: '35px',
        color: '#4C4948',
        letterSpacing: '-0.05px',
        fontWeight: 500,
        marginLeft: '40px'
        // "&::first-letter": {
        //     fontSize: '13px',
        //     paddingRight: '6px'
        // }
    },
    smallTitle: {
        display: 'inline-block',
        marginLeft: '24px',
        color: '#4D4E53',
        fontWeight: 600,
        fontSize: '12px'
    },
    smallMoney: {
        display: 'inline-block',
        marginLeft: '1em',
        color: '#4D4E53',
        fontWeight: 400,
        fontSize: '12px'
    },
}));

const CostSummary = (props) => {
    const classes = useStyles();
    const { generalData, loading, granularity, type, className } = props;

    const totalAmount = useMemo(() => {
        if (!generalData || generalData.ResultsByTime.length === 0) {
            return 0;
        }
        return generalData.ResultsByTime.reduce((total, item) => {
            const itemAmount = item.Groups.reduce((subTotal, sutItem) => {
                return Decimal.add(subTotal, sutItem.Metrics.UnblendedCost.Amount).toString();
            }, '0')
            total = Decimal.add(total, itemAmount).toString();
            return total;
        }, '0');


    }, [generalData]);

    const totalAmountTxt = useMemo(() => {
        return new Decimal(totalAmount).toNumber().toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        })
    }, [totalAmount]);

    const averageAmount = useMemo(() => {
        if (!generalData || generalData.ResultsByTime.length === 0) {
            return 0;
        }
        return Decimal.div(totalAmount, generalData.ResultsByTime.length).toString();
    }, [generalData, totalAmount]);

    const averageAmountTxt = useMemo(() => {
        return new Decimal(averageAmount).toNumber().toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        })
    }, [averageAmount]);

    if (type === 'small') {
        return <Box className={`${classes.smallContainer}${className ? ` ${className}` : ''}`}>
            <Typography className={classes.smallTitle}>Total cost: </Typography>
            {loading ? <CircularProgress className={classes.loading} size={10} /> : <Typography className={classes.smallMoney}>{totalAmountTxt}</Typography>}

            <Typography className={classes.smallTitle}>Average {granularity.toLowerCase()} cost: </Typography>
            {loading ? <CircularProgress className={classes.loading} size={10} /> : <Typography className={classes.smallMoney}>{averageAmountTxt}</Typography>}
        </Box>
    }

    return <Box className={`${className ? className : ''}`}>
        <Box className={classes.container}>
            <Box className={classes.itemContainer}>
                <Box className={classes.item}>
                    {loading ? <CircularProgress className={classes.loading} size={10} /> : <>
                        <Typography className={classes.itemTitle}>
                            <img src={totalIconPic} alt='Total Cost' />
                            Total cost
                            <span className={classes.itemMoney}>{totalAmountTxt}</span>
                        </Typography>
                    </>}

                </Box>
            </Box>
            <Divider className={classes.divider} orientation='vertical' flexItem />
            <Box className={classes.itemContainer}>
                <Box className={classes.item}>
                    {loading ? <CircularProgress className={classes.loading} size={10} /> : <>
                        <Typography className={classes.itemTitle}>
                            <img src={averageIconPic} alt='Average Cost' />
                            Average {granularity.toLowerCase()} cost
                            <span className={classes.itemMoney}>{averageAmountTxt}</span>
                        </Typography>

                    </>}
                </Box>
            </Box>
        </Box>
    </Box>

}

export default CostSummary;