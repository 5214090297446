import React, { useEffect, useMemo, useState } from 'react';

import AWS from "aws-sdk";
import { makeStyles } from "@material-ui/core/styles";

import { cb2Promise, useGetAssumedKeysForBuildAccount, useHandleAwsAuthErr } from '../../common/AwsUtil';
import { useDispatchReducerContext } from '../../store';
import { DISMISS_APP_LOADING, SHOW_APP_LOADING, SHOW_TOAST } from '../../store/dispatchNames';
import ConfirmDialog from '../../components/ui/ConfirmDialog';
import approvePic from '../../assets/icons/approve_white.png';
import declinePic from '../../assets/icons/decline_white.png';
import { CommonDialog, CommonDialogActions, CommonDialogContent } from '../../components/ui/CommonDialog';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CancelButton from '../../components/ui/CancelButton';
import CommonButton from '../../components/ui/CommonButton';
import CommonTextField from '../../components/ui/CommonTextField';

const useProdApproveConfirmClasses = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            width: '500px'
        }
    },
    title: {
        color: '#4C4948',
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '31px',
        paddingTop: '70px'
    },
    subtitle: {
        color: '#4C4948',
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: 400,
        paddingTop: '4px'
    },
    inputContainer: {
        width: '250px',
        margin: '68px auto 0',
    },
    actionsContainer: {
        justifyContent: 'center',
        padding: '100px 0 56px'
    },
    button: {
        width: '130px'
    },
    approvalActionIcon: {
        width: '18px',
        height: '18px',
        position: 'relative',
        top: '-1px'
    },
}))

const ProdApproveConfirm = (props) => {
    const classes = useProdApproveConfirmClasses();
    const [requestNumber, setRequestNumber] = useState("");
    const [requestNumberValidMsg, setRequestNumberValidMsg] = useState("");
    useEffect(() => {
        if (props.open) {
            setRequestNumber("")
            setRequestNumberValidMsg("")
        }
    }, [props.open]);

    const clickConfirm = () => {
        setRequestNumberValidMsg('');
        if (requestNumber.trim() === '') {
            setRequestNumberValidMsg("Please provide change request number");
            return;
        }
        props.onConfirm(requestNumber);
    }
    return <CommonDialog
        open={props.open}
        onClose={props.onClose}
        className={classes.container}
    >
        <CommonDialogContent>
            <Typography className={classes.title} variant='h6'>Approve</Typography>
            <Typography className={classes.subtitle}>Please enter change request number</Typography>
            <Box className={classes.inputContainer}>
            <CommonTextField
                required
                error={requestNumberValidMsg !== ''}
                helperText={requestNumberValidMsg}
                InputLabelProps={{ shrink: true }}
                autoComplete='new-password'
                fullWidth
                margin="normal"
                value={requestNumber}
                onChange={(e) => setRequestNumber(e.target.value)}
                label="Change request number"
                type="password"
            />
            </Box>
            
        </CommonDialogContent>
        <CommonDialogActions className={classes.actionsContainer}>
            <CancelButton onClick={props.onClose} className={classes.button}>
                Cancel
            </CancelButton>
            <CommonButton onClick={clickConfirm} startIcon={<img className={classes.approvalActionIcon} src={approvePic} alt='trigger' />} className={classes.button}>
                Approval
            </CommonButton>
        </CommonDialogActions>
    </CommonDialog>
}

const useStyles = makeStyles((theme) => ({

    approvalActionIcon: {
        width: '18px',
        height: '18px',
        position: 'relative',
        top: '-1px'
    },
}));

const useApprove = (owner, projectName, onRefreshRequest) => {
    const classes = useStyles();
    const getAssumedKeys = useGetAssumedKeysForBuildAccount();
    const handleAwsAuthErr = useHandleAwsAuthErr();
    const dispatch = useDispatchReducerContext();
    const [isShowConfirmApprove, setIsShowConfirmApprove] = useState(false);
    const [status, setStatus] = useState("Approved");
    const [action, setAction] = useState(null);

    const isShowProdConfirm = useMemo(() => {
        if (!action) return false;
        return ["APPROVE-IN-PROD", "APPROVE-IN-DATALAKE-PROD"].includes(action.actionName) || (action.actionName === 'Approve' && projectName === 'ud-cicd-engine')
    }, [action, projectName])

    const handleApproveClick = (status, action) => {
        setStatus(status);
        setAction(action);
        setIsShowConfirmApprove(true);
    }

    const approveProject = async () => {
        let launchToken = "";
        let env = "nonprod";
        if (action.actionName === "APPROVE-IN-PROD" || action.actionName === "APPROVE-IN-DATALAKE-PROD") {
            env = "prod";
        }
        dispatch({
            type: SHOW_APP_LOADING
        })
        try {
            const params = await getAssumedKeys(owner, env);
            const codepipeline = new AWS.CodePipeline(params);
            const pipelineState = await cb2Promise(codepipeline, "getPipelineState", {
                name: projectName
            })
            let stageName;
            pipelineState.stageStates.forEach((stage) => {
                if (stage.stageName === "DEPLOY-IN-TEST" || stage.stageName === "Deploy-In-Test" || stage.stageName === "DEPLOY-IN-QA" || stage.stageName === "Deploy-In-QA" || stage.stageName === "DEPLOY-IN-DATALAKE-QA" || stage.stageName === "DEPLOY-IN-PROD" || stage.stageName === "Deploy-In-Prod" || stage.stageName === "DEPLOY-IN-DATALAKE-PROD" || stage.stageName === "deploy" || stage.stageName === "Deploy" || stage.stageName === "LOAD-TEST" || stage.stageName === "DESTROY") {
                    stage.actionStates.forEach((stageAction) => {
                        if (stageAction.actionName === action.actionName && stageAction.latestExecution.status === "InProgress") {
                            stageName = stage.stageName;
                            launchToken = stageAction.latestExecution.token;
                            console.log("Token:: ", launchToken);
                        }
                    })
                }
            });
            await cb2Promise(codepipeline, 'putApprovalResult', {
                actionName: action.actionName,
                pipelineName: projectName,
                result: {
                    status: status,
                    summary: status,
                },
                stageName: stageName,
                token: launchToken
            });
            setIsShowConfirmApprove(false);
            dispatch({
                type: SHOW_TOAST,
                payload: {
                    message: 'Successed',
                    severity: 'success'
                }
            });
            setTimeout(() => {
                onRefreshRequest();
            }, 2000);
        } catch (err) {
            if (!handleAwsAuthErr(err)) {
                dispatch({
                    type: SHOW_TOAST,
                    payload: {
                        message: err.message,
                        severity: 'error'
                    }
                });
            }
        } finally {
            dispatch({
                type: DISMISS_APP_LOADING
            })
        }

    }

    const approveComp = (isShowProdConfirm && status === "Approved" ? <ProdApproveConfirm
        open={isShowConfirmApprove}
        onClose={() => setIsShowConfirmApprove(false)}
        onConfirm={approveProject}
    /> : <ConfirmDialog
        open={isShowConfirmApprove}
        onClose={() => setIsShowConfirmApprove(false)}
        title={`Are you sure you want to ${status === "Approved" ? "approval" : "decline"}?`}
        onConfirm={approveProject}
        confirmText={status === "Approved" ? "Approval" : "Decline"}
        icon={<img className={classes.approvalActionIcon} src={status === "Approved" ? approvePic : declinePic} alt='trigger' />}
    />)

    return {
        handleApproveClick,
        approveComp
    }
}

export default useApprove;
